
/*************************
* DISKUZE
*************************/


/*************************
* ÚVOD DISKUZE - PŘEHLED
*************************/

.diskuzeTable td,
.diskuzeTable th,
.diskuzeTable {
	border-color: #EDEDEE;
}

.diskuzeTable {
	th {
		background-color: #EDEDEE;
		border: 1px white solid;
		color: #000000;
		padding: 8px 20px;

		#{selector-link()},
		a:active,
		a:focus {
			color: #25426f;
		}

		a:hover {
			color: #000000;
		}
	}

	div {
		background-color:#f7f6f6;
	}
	
	th.typfora div {
		background-color: #25426F;
		color: #ffffff;
	}
}


/*************************
* VLASTNÍ DISKUZE
*************************/

.ZOtec,
#legenda .otec {
	background-color: #25426F;
	color: #ffffff;
} //korenovy nadpis v diskuzi - barva zahlavi

.podzahlavim,
.reakce {
	background-color: #EDEDEE;
} //cesta a hlavni text ve vlastni diskuzi + okraj pomyslneho listu vlevo od reakce

.ZReakce {
	background-color: #4C6997;
	color: #ffffff;
} //barva zahlavi reakce

.reakce .podzahlavim {
	background-color: #F3F3F3;
}  //podbarveni reakce

.dnesni {
	background-color: #FF0000;
	color: #000000;
} //uzky prouzek signalizujici dnesni prispevek (vyrazne jina barva nez pozadí listy - nejspis cervena + barva textu dostatecne kontrastni)

.vcerejsi {
	background-color: #FECB00;
	color: #000000;
} //uzky prouzek signalizujici vcerejsi prispevek (vyrazne jina barva nez pozadí listy - nejspis zluta + barva textu dostatecne kontrastni)

.ZReakce a,
#{selector-link-all(".ZReakce a")},
.reakce .ikony a,
#legenda dt.reakce a {
	color: #ffffff;
} //odkazy na liste reakce

#{selector-link-all(".ZOtec a")},
.otec .ikony a,
#legenda dt.otec a {
	color: #ffffff;
} //odkazy na liste korene

@include link-all(".podzahlavim a") {
	color: #25426f;
} //odkazy pod listou u korene i reakce

#legenda {
	dd {
		margin-left: 3em;
	}
}

.podzahlavim strong {
	color: #000000;
} //moznost odliseni nazvu tematu

#celek .feditace form div {
	border-color: white;
} //delici linky ve formulari

.diskuze {
	.ikony,
	.ikony a {
		width: auto;
	}

	.ikony a {
		padding: 0 5px;
		display: inline-block;
		float: none;
	}
}


/***************************
* PŘEPISY TINYMCE
***************************/

#diskuze .mce-btn button {
	width: auto;
}

#celek .feditace form {
	.mce-container,
	.mce-container div {
		padding: 0;
	}

	.mce-container .mce-statusbar {
		padding: 3px 5px;
	}
}