
#google_translate_element {
	display: inline-block;
	position: relative;
	top: -1px;
	z-index: 10;
	visibility: visible !important;
	border-radius: 3px;
	margin: 0 30px 0 15px;

	&.pole {
		background-color: transparent;
	}

	.goog-te-gadget img {
		vertical-align: middle;
		border-radius: 3px;
	}

	.goog-te-gadget-simple {
		border: 0;
		width: 100%;
		padding-bottom: 1px;
		background-color: transparent;
		
		&,
		& * {
			font-size: rem(16px) !important;
		}

		[aria-haspopup][href='#'] {
			margin-right: 0;
			display: inline-block;
			text-decoration: none!important;

			span {
				&:nth-of-type(1) {
					padding: 0 3px;
					display: none;
					color: #000000;
				}
				
				&:nth-of-type(2) {
					padding-right: 3px;
					border-left: 0!important;
				}
				
				&:nth-of-type(3) {
					background: none;
					color: #ffffff!important;
				}
			}

			img {
				display: none;
			}
		}
	}
}

@include bp("< 710px") {
	#google_translate_element,
	#goog-gt-tt,
	#goog-gt-tt + div > object,
	iframe.goog-te-menu-frame {
		display: none!important;
	}
}