
/*******************
* NADPISY
*******************/

h1.cvi {
	font-size: rem(16px);
	padding: 0;
	line-height: 1.1;
	margin: 0 12px 0 20px;
	font-family: $font;
	font-weight: 500;
	text-transform: uppercase;
	color: $barL;
	float: left;

	strong {
		font-size: rem(48px);
		font-weight: 700;
	}

	span{
		display: block;
		width: 10.5em;
		line-height: 1.3;
	}

	@include bp("> 1250px"){
		padding: 14px 0 0 0;
		margin: 0 0 0 16px;

		strong{
			font-size: rem(57px);
		}

		span{
			display: inline-block;
			line-height: 1.5;
			margin-left: 18px;
			letter-spacing: 0.05em;
		}
	}	
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
#prohlaseni h3,
h4 {
	font-family: $font;
}

h2.nadpis, h2.cvi{
	text-transform: uppercase;
	font-size: rem(28px);
	font-family: $font;
	font-weight: bold;
	margin: 0;
	padding: 16px 0;

	@include bp("> 1000px"){
		font-size: rem(34px);
	}

	@include bp("> 1500px"){
		font-size: rem(40px);
	}
}

h2.cvi,
.poz h2.cist {
	font-size: rem($h2-size);
	color: $barD;
	margin: 0;
	padding: 8px 0;
	line-height: 1.2;
}

#zahlavi h2.cvi {
	margin: 0 0 20px 0;
	padding: 0;
}

// Hlavní nadpisy na podstránce
#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
	font-size: rem(34px);
	color: $barD;

	@include bp("> 1500px"){
		font-size: rem(40px);
	}
}

h3 {
	.editor &,
	&.cvi,
	&.ud,
	#prohlaseni & {
		font-size: rem($h3-size);
		font-weight: normal;
		margin: 25px 0 5px 0;
		padding: 8px 20px 4px 0;
		line-height: 1.2;
		color: $barD;
	}
}

h4 {
	font-size: rem($h4-size);
	line-height: 1.2;
	color: $barD;
}

h4.urd-list {
	font-size: rem(25px);
	padding-top: .75em;
	float: none;
	border-top: 3px #cccccc solid;
	padding-right: 7em;
	color: $barD;
	font-weight: bold;

	+ .udz.urd-list {
		margin-top: -1.6em;
		margin-bottom: 2em;
		float: none;
		text-align: right;

	}

	@include bp("< 1024px") {
		padding-right: 0;
		
		+ .udz.urd-list {
				margin-top: 1em;
		}
	}

}

h5 {
	font-size: 1.1em;
}