
/*************************
* ŠABLONY
*************************/


/*************************
* DOKUMENTY
*************************/


%card {
   margin-top: 0;
   overflow: hidden;
   flex-grow: 1;

   img {
      float: none;
      display: table;
      margin: 0;
      width: 100%;
   }

   strong a{
       font-size: rem(19px);
       font-family: $font;
       font-weight: 500;
       color: $barL;
       text-transform: uppercase;
       text-decoration: underline;

       @include bp("> 800px"){
            font-size: rem(23px); 
       }

       &:hover {
           color: $barD;
           text-decoration: none;
       }
   }

   .dok-datum {
      margin-top: 10px;
   }

   .dok-upoutavka {
      display: block;
      overflow: hidden;
      margin: 0;
      
      img {
         margin-bottom: 10px;
      }
   }

   .dok-nazev {
      display: block;
   }

    .popis, .vd-priloha {
        margin-top: 12px;
        font-family: $font2;
        font-size: rem(16px);
        font-weight: 300;
        color: #000000;
        display: block;
        line-height: 1.8;
    }

    .vd-priloha{
        margin-bottom: 12px;
        color: $barL;
        line-height: 1.375;
    }

    .odkazVice{
        padding-right: 20px;
        position: absolute;
        right: 24px;
        bottom: 26px;
        color: #4c6997;

		&::after{
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 14px;
			background-image: url(images/sipkaDok.png), url(images/sipkaLinkaDok.png);
			background-repeat: no-repeat;
			background-position: left center;
			transition: background-position 0.5s;
		}

		&:hover::after{
            background-image: url(images/sipkaDokHover.png), url(images/sipkaLinkaDok.png);
			background-position: right center, left center;
		}
	}
}
