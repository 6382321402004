
/**************************
* OBECNÉ
**************************/

html {
	touch-action: manipulation;
}

html,
body {
	background-color: #ffffff;
}

body {
	@extend %reset;
	text-align: center;
}

@include selection {
	background-color: $color-main;
	color: #ffffff;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.patalogo):not(.expandable):not(.menu-link):not(.mouse-focus):focus {
	border-radius: 3px;
	box-shadow: 0 0 0 2000px $link-focus-bg inset, 0 0 0 3px $link-focus-bg !important;
	color: $link-focus-text !important;
	outline: 0;
}

img {
	color: inherit;
}


/*************************
* HLAVNÍ OBSAH
*************************/

.section {
	padding: {
		top: 3em;
		bottom: 3em;
	}
}

#navigace, #navigace2, #hlobsah{
	display: block;
}

.centrovany {
	@extend %sf;
	width: 94%;
	max-width: $max-width;
	margin: 0 auto;
	text-align: left;
}

 
#stred,
#vpravo,
#vlevo {
	@extend %border-box;
	width: 100%;
	margin-bottom: 45px;
}

#vpravo,
#vlevo {
	word-wrap: break-word;
}

#stranka #stred {
	padding: 15px 0;
	font-family: $font2;
	font-size: rem(17px);
}

.stredUzsi{
	width: 100%;
	max-width: 770px;

	@include bp("> 800px"){
		float: left;
	}
}

.sirsi-stred{
	.stredUzsi{
		float: none;
		max-width: 100%;
	}
}


/*************************************
* FLEX LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/

@include bp("> 800px") {
	#stranka #telo {
		display: flex;
		justify-content: space-between;
	}

	#stred {
		width: 59%; //kvůli IE11
		order: 1;
	}

	#vlevo {
		width: 36%;
	}
}

@include bp("> 1000px") {
	#stred {
		width: 63%;
	}

	#vlevo {
		width: 32%;
	}
}

@include bp("> 1200px") {
	#stred {
		width: ratio($max-width, 1058px); 
	}

	#vlevo {
		width: ratio($max-width, 428px);
	}
}

/************************
* PRVKY STRÁNKY
************************/

#stranka #obalcelek{
	background-color: #f3f3f3;

	@include bp("> 1000px"){
		background-image: url(images/bg_strankaLT.png), url(images/bg_strankaRB.png);
		background-repeat: no-repeat;
		background-position: center top, center bottom;
	}
}

.cesta {
	font-size: rem(16px);
	padding: 22px 0 18px 0;
	margin: 0;
	position: relative;
	font-family: $font;

	@include bp("> 600px"){
		font-size: rem(18px);
	}

	&,a{
		color: #000000;
	}

	strong {
		font-weight: normal;
	}
}

.dalsi {
	font-size: rem(18px);
	margin: 15px 0 0 0; 
	padding: 5px 0 0 0;
	text-align: left;
	font-family: $font;

	a {
		display: inline-block;
		padding: 10px 30px;
	}

	@include link {
		text-decoration: none;
	}

	@include link-over {
		text-decoration: underline;
	} 
}

a.tlacitko{
	display: inline-block;
	font-family: $font2;
	font-size: rem(18px);
	font-weight: 300;
	color: white;
	background: $barD;
	padding: 1em 2.25em;
	border-radius: 3px;
}


/************************
* ZÁPATÍ
************************/

#nadpatou {
	background: $barD;
	padding: 40px 0 50px;
	font-size: rem(18px);
	font-family: $font2;
	font-weight: 300;

	&,
	h2,
	h3,
	#{selector-link()} {
		color: #ffffff;
	}
	
	h2{
		padding-bottom: 0;
	}

	strong{
		font-weight: bold;
	}

	#kontakt{
		max-width: 1100px;
		float: left;
		width: 100%;
		line-height: 1.6;
	}

	.np-co{
		display: block;
	}

	@include bp("> 900px"){
		.np-co{
			float: left;
			box-sizing: border-box;
			padding-right: 3%;
		}
	
		.co-1{
			width: 38%;
		}
	
		.co-2{
			width: 31%;
		}
	
		.co-3{
			width: 31%;
			padding-right: 0;
		}
	}
}

.centKrVys{
	text-align: center;
	padding: 44px 0 4px;
}

#obalpata {
	background-color: $footer-background;
	min-width: 320px;
}

#pata {
	text-align: center;
	padding: 22px 16px 28px;
	color: $footer-text; 
	box-sizing: border-box;
	font-size: rem(14px);

	* {
		line-height: 2.2;
		border: 0;
	}

	li + li::before{
		content: "\|";
		margin: 0 8px 0 0;
	}

	.partWebmaster1::before{
		content: "\|";
		margin: 0 10px 0 0;
	}

	@include link {
		color: $footer-link;
		text-decoration: underline;
	}

	@include link-over {
		text-decoration: none;
	}
	
	.patalogo {
		padding: 1px 6px;
		margin-right: 11px;
	}

	#pocitadlo{
		margin: 0!important;
		padding-bottom: 0!important;
	}

	.webmaster,
	.inline {
		display: inline-block;
		margin: 0;
		padding: 0 0 0 3px;

		li {
			padding: 0 5px;

			a {
				margin: 0 0 0 1px;
			}
		}
	}

	.webmaster {
		display: block;

		#stranka &{
				margin-top: 2px;
			}

		.partWebmaster {
			display: inline-block;
			margin-left: 5px;
		}
	}

	ul.inline li {
		display: inline-block;
	}
}

@include bp("< 500px"){
	.partWebmaster1 {
		display: block!important;
		margin-top: 2px;

		&::before{
			content: ""!important;
			margin:0!important;
		}	
	}	
}

#unpobtekane {
	margin-left: 0 !important;
}


@if is-bit-set($web_bitmask, $BIT_SKRYT_KONTEXT) {
	.zobrazeno.kontext2 {
		display: none;
	}
}

@if is-bit-set($web_bitmask, $BIT_SKRYT_ZODPOVIDA) {
	.popis.dpopis {
		display: none;
	}
}

// oprava sirky obrazku v tabulce
.obsah .editor table img{
	max-width: 140px !important;
	margin: 4px !important;
}

// odrardkovani telefonu v oslozce
.utvary .ui .o .phone .comma, .utvary .ui .o .person-type + .email .comma {
	&::after {
		content: "";
		display: table;
		height: 0;
		clear: both;
	}
}
