
/*****************************************
* MIXINY A PROMĚNNÉ PRO TABULKOVÉ MENU
*****************************************/

$menu-spacing: 10px;
$menu-colors: #212922 #294936 #3e6259 #5b8266;

/****************************************/


/******************************
* HORIZONTÁLNÍ MENU A VÝBĚRY
******************************/

#obalmenu1 {
	position: relative;
	z-index: 15;
	overflow: hidden;

	@include bp("> 600px", "<= 800px"){
		border-top: 72px solid #25426F;
	}
}

#menu1 {
	box-sizing: border-box;

	ul {
		@extend %reset-list;
		text-align: center;
		padding: 10px 0 8px;
	}

	.akt a{
		font-weight: bold;
		letter-spacing: -0.025em;
		
		&::after{
			content: "";
		}

		&:hover{
			text-decoration: none;
		}
	}

	li {
		line-height: 20px;
		font-size: rem(16px);
		font-family: $font;
		padding: 11px 0.6em 0;
		text-transform: uppercase;
		position: relative;
		display: inline-block;

		@include bp("> 1360px"){
			font-size: rem(18px);
		}

		@include bp("> 1500px"){
			font-size: rem(20px);
		}

		@include link {
			text-decoration: none;
			color: #ffffff;
		}

		@include link-over {
			text-decoration: underline;
		}

		@include link-all("#osmakth") {
			cursor: text;
			text-decoration: none;
		}
	}

	@include bp("> 600px"){
		ul{
			padding: 8px 0;
		}
	}

	@include bp("> 800px"){
		padding: 0 0 0 300px;

		ul{
			padding: 0;
			height: 76px;
			text-align: left;
		}
	}	

	@include bp("> 935px"){
		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			width: calc(100% + 0.5em);
			font-size: rem(20px);
			margin-right: -0.5em;
		}

		li {
			padding: 12px 0.5em;

			a{
				&::after{
					content: normal;
					position: absolute;
					bottom: -16px;
					display: block;
					width: 31px;
					height: 15px;
					margin-left: -15px;
					left: 50%;
					background: url(images/sipkaMenu.png) no-repeat 0 0;
				}
	
				&:hover::after{
					content: "";
				}
			}
		}	
	}
	
	@include bp("> 1250px"){
		padding-left: 500px;
	}

	@include bp("> 1350px"){
		padding-left: 520px;
	}
}

@include bp("> 900px"){
	.fixed-menu {
		#obalhlava{
			height: 53px; 
			position: fixed;
			width: 100%;
			max-width: 1920px;
			box-sizing: border-box;
			text-align: left;
			margin-top: 53px;
			top: -53px;
			padding: 8px 0;
			z-index: 100;
			left: 50%;
			transform: translateX(-50%);	
			transition: margin .75s;
			border-bottom: 1px solid #ffffff;
			background-color: $barL;		
		}

		#hlava{
			max-width: 1540px;
			margin: 0 auto;
		}

		#lista{
			display: none;
		}

		.obalNazev{
			background: transparent;

			.centrovany{
				width: 100%;
			}

			#nazev{
				background: transparent;
				padding: 0;
				width: auto;

				&::before{
					content: normal;
				}
			}

			img, h1.cvi span{
				display: none;
			}

			h1.cvi{
				margin: 0 0 0 22px;
				padding: 0;

				strong{
					font-size: rem(32px);
					display: block;
					color: white;
				}	
			}	
		}

		#obalmenu1{
			#menu1{
				padding-left: 250px;
			}
			
			ul{
				height: 37px;
				max-width: 1020px;
				float: right;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
				width: calc(100% + 0.5em);
				margin-right: -0.5em;
			}
	
			li{
				padding: 0;
	
				a:hover::after, &.akt a::after{
					content: normal;
				}
			}
		}
	}


	// 		height: 53px; 
	// 		position: fixed;
	// 		width: 100%;
	// 		max-width: 1920px;
	// 		box-sizing: border-box;
	// 		text-align: left;
	// 		margin-top: 53px;
	// 		top: -53px;
	// 		padding: 8px 0;
	// 		z-index: 100;
	// 		left: 50%;
	// 		transform: translateX(-50%);	
	// 		transition: margin .75s;	

	// 	h1.cvi {
	// 		height: 53px; 
	// 		position: fixed;
	// 		width: 100%;
	// 		max-width: 1920px;
	// 		z-index: 99;
	// 		box-sizing: border-box;
	// 		text-align: left;
	// 		margin: 53px 0 0;
	// 		top: -53px;
	// 		padding: 10px 0;
	// 		left: 50%;
	// 		transform: translateX(-50%);	
	// 		transition: margin-top .75s;
	// 		border-bottom: 1px solid #ffffff;
	// 		background-color: $barL;			

	// 		strong{
	// 			max-width: $max-width;
	// 			margin: 0 auto;	
	// 			width: 94%;
	// 			font-size: rem(32px);
	// 			display: block;
	// 			color: white;
	// 		}

	// 		span{
	// 			display: none;
	// 		}
	// 	}
	// }
}

// @include bp("> 500px", "<= 800px") {
// 	#menu1 {
// 		ul {
// 			display: flex;
// 			flex-wrap: wrap;
// 		}

// 		li {
// 			box-sizing: border-box;
// 			@include grid-column(2, 2%);
// 			float: left;
// 			margin-bottom: 15px;
// 		}
// 	}
// }

// @include bp("> 800px") {
// 	#menu1 ul,
// 	#vybrane .bg {
// 		display: flex;
// 		margin-left: -$menu-spacing;
// 		margin-right: -$menu-spacing;
// 	}

// 	#menu1 li,
// 	#vybrane [class*="sekce"] {
// 		flex: 1 1 (100% / 4);
// 		margin-left: $menu-spacing;
// 		margin-right: $menu-spacing;
// 	}
// }

// @for $i from 1 through length($menu-colors) {
// 	#p#{$i},
// 	#vybrane .sekce0#{$i} {
// 		background: nth($menu-colors, $i);
// 	}
// }

/*************************
* VÝBĚRY MENU
*************************/

// #vybrane {
// 	display: none;

// 	@include bp("> 800px") {
// 		display: block;
// 	}

// 	.bg {
// 		@include link {
// 			color: #20c997;
// 			text-decoration: none;
// 		}
		
// 		@include link-over {
// 			text-decoration: underline;
// 		}
// 	}

// 	ul {
// 		list-style-type: none;
// 		margin: 0;
// 		padding: 15px 22px 10px 22px;
		
// 		li {
// 			padding: 0;
// 			display: inline;
// 		}

// 		.pam {
// 			font-size: rem(11px);
// 			border-left: 1px #444444 solid;
// 			margin: 0 0px 0 9px;
// 			padding: 0;
// 			vertical-align: middle;
// 			position: relative;
// 			top: -1px;
// 		}

// 		.pampv .pam {
// 			display: none;
// 		}

// 		@include link(".pamdalsi a") {
// 			text-decoration: underline;
// 		}
// 	}
// }

// MENU DALŠÍ ÚROVNĚ 
// =================

#stranka.noleftpanel{
	
	#telo{
		max-width: 100%;
		
		#stred{
			width: 100%;
			float: none;
			clear: both;
			padding: 14px 0 0!important;

			#zahlavi {
				h2.cvi{
					margin: 0 0 26px;
				}	
			}
		}
	}	

	#menu-start {
		display:none;
	}

	.mapa-stranek-2016{
		clear: both;

		.odkazy.souvisejici {
			position: relative;
			margin-bottom: 44px;

			ul.ui{
				li{
					@include grid(501px 2 1.5%, 800px 3, 1100px 4);
				}
			}
		}	
	}

	.dok{
		max-width: 800px;
	}
}

#osnova *{
	font-style: normal;
}

.mapa-stranek-2016{
	h3.souvisejiciodkazy{
		display: none;
	}

	.editor{
		margin-top: 30px;
		max-width: 100%;
	}

	.odkazy.souvisejici ul.ui{
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;

		li{
			@include grid(501px 2 2.5%, 1100px 3);
			margin-top: 10px;
			width: 100%;
			box-sizing: border-box;
			background: #ffffff;
			position: relative;
			min-height: 120px;

			&:hover{
				background: #e2e5ea;
			}
	
			@include bp("> 500px"){
				margin-top: 14px;
				margin-bottom: 14px;
			}
			a{
				border-top: 19px solid $barL;
				color: $barD;
				padding: 20px 5%;
				display: block;
				text-decoration: none;
				box-sizing: border-box;
				text-align: left;
				font-size: rem(18px);
				font-weight: normal;

				&::before{
					@include fill;
					content: "";
				}

				&:hover{
					border-top-color: $barD;
					text-decoration: underline;

					&::after{
						content: "";
						position: absolute;
						right: 5%;
						top: 16px;
						width: 40px;
						height: 23px;
						background: url(images/sipkaTemata.png) no-repeat 0 0;
						display: block;
					}	
				}

				@include bp("> 1200px"){
					font-size: rem(20px);
					padding: 20px 8%;

					&:hover::after{
						right: 8%;
					}	
				}
			}

			div{
				color: #444444;
				font-size: rem(16px);
				padding: 0 5% 12px;

				@include bp("> 1200px"){
					padding: 0px 8% 16px;
				}	
			}
		}
	}

	.pataprvku, .popis{
		margin: 30px 0;
	    box-sizing: border-box;
	    font-size: 16px;
	    max-width: 722px;
	    width: 100%;
	}

	.pataprvku{
		padding-top: 30px;
		border-top: 1px solid #dfdfdf;
	}
}

@if $bool_dlazdice == false {

	/***********************
	*** ROZBALOVACÍ MENU ***
	***********************/

	#menu ul {
		@extend %reset-list;
	}

	.menu-controls,
	#menu-start {
		display: none;
	}

	.nadmenu,
	#nadmenu {
		display: none;
	}


	/**********************
	* MOBILNÍ MENU
	**********************/

	#menu {
		background-color: $menu-bg;
		padding: 20px;

		> ul {

			a {
				padding: 5px 20px;
				margin-left: -20px;
				display: block;
			}

			@include link {
				color: $menu-link-color;
			}

			@include link-all("#osmakt") {
				background-color: $menu-active-bg;
				color: $menu-active-text;
				text-decoration: none;
			}
		}

		ul {
			padding-left: 20px;
		}
	}


	@include bp("<= #{$menu-tablet-size}") {
		#menu-start {
			display: block;
			clear: both;
			text-align: center;

			a {
				background: $menu-trigger-bg;
				display: block;
				padding: 15px 0;

				&::before {
					display: inline-block;
					vertical-align: middle;
					margin: -.15em .75em 0 0;
					content: encode-svg('<svg xmlns="http://www.w3.org/2000/svg" width="30" height="22" viewBox="0 0 30 22"><path fill="#{$menu-trigger-text}" fill-rule="evenodd" clip-rule="evenodd" d="M29 22h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1z"/></svg>');
				}
			}

			@include link {
				color: $menu-trigger-text;
				text-decoration: none;
			}
		}

		.no-scroll {
			height: 100%;
			touch-action: none;
			overflow: hidden;
			pointer-events: none;
			-webkit-overflow-scrolling: auto;

			body {
				height: 100%;
				touch-action: none;
				overflow: hidden;

				// > {
				// 	div {
				// 		height: 0;
				// 		touch-action: none;
				// 		overflow: hidden;
				// 	}
				// }
			}
		}

		#menu.menu-initialised {
			pointer-events: auto;
			box-sizing: border-box;
			z-index: 200;
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			height: 100%;
			max-width: 400px;
			height: 100vh;
			padding: 70px 20px 20px 0;
			transition: transform .3s ease-out;
			-webkit-transform: translateX(-100%);
			transform: translateX(-100%);
			opacity: 0;
			-webkit-transition: -webkit-transform .3s ease-out;

			[data-env="Safari"] & {
				max-width: 100%;
			}

			&.menu-active {
				-webkit-transform: translateX(0);
				transform: translateX(0);
				opacity: 1;
			}

			> ul {
				// position: relative;
				box-sizing: border-box;
				width: 100%;
				height: 100%;
				margin: 0;
				padding: 0 0 0 20px;
				overflow-y: auto;
				-webkit-overflow-scrolling: touch;
				z-index: 101;

				> li {
					margin-bottom: 15px;

					&:last-child {
						margin-bottom: 80px;
					}
				}
				// ul {
				// 	padding: 0 0 0 20px;
				// }
			}

			ul {
				list-style-type: none;
			}

			.menu-controls {
				position: fixed;
				left: 0;
				top: 0;
				right: 0;
				display: block;
				height: 50px;
				z-index: 100;
				//width: 100%;
				background-color: $menu-topbar-bg;
				color: $menu-topbar-text;
				//display: table;

				.menu-control-back, 
				.menu-control-title {
					display: block;
				}

				.menu-control-back {
					position: absolute;
					width: 50px;
					height: 50px;
					right: 0;
					top: 0;
					background: {
						image: encode-svg('<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path fill="#{$menu-topbar-text}" fill-rule="evenodd" d="M13.569 10.994l8.162 8.165c.355.358.355.936 0 1.294l-1.28 1.279c-.357.358-.935.358-1.293 0l-8.16-8.166-8.162 8.166c-.357.358-.935.358-1.292 0l-1.278-1.279c-.357-.358-.357-.936 0-1.294l8.162-8.165-8.152-8.154c-.357-.357-.357-.935 0-1.292l1.279-1.28c.356-.357.935-.357 1.292 0l8.151 8.154 8.152-8.154c.357-.357.935-.357 1.293 0l1.276 1.28c.359.357.359.935 0 1.292l-8.15 8.154z"/></svg>');
						repeat: no-repeat;
						position: 50% 50%;
						size: 22px 22px;
					}
				}

				.menu-control-title {
					padding: 0 20px;
					line-height: 2.2;
					border-right: 70px transparent solid;
					white-space: nowrap;
					font-size: rem(20px);
					-ms-text-overflow: ellipsis;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			.akt a{
				font-weight: bold;
				text-decoration: none;

				&:hover{
					text-decoration: none;
				}
			}

			.menu-indicator {
				display: none;
			}
		}
	}


	/***********************
	* DESKTOPOVÉ MENU
	***********************/

	@include bp("> #{$menu-tablet-size}") {
		.nadmenu {
			display: block;
			
			a{
				position: relative;
				color: $barL;
				font-size: rem(19px);
				padding: 16px 16px 16px 60px;
				display: block;
				text-decoration: none;
				line-height: 1.15;
				background-color: #ffffff;
				
				&::before{
					content: "";
					position: absolute;
					left: 20px;
					top: 50%;
					margin-top: -17px;
				    display: inline-block;
				    width: 15px;
				    height: 34px;
					background: url(images/sipkaZpet.png) no-repeat 0 0;
				}

				@include bp("> 1100px"){
					font-size: rem(22px);
					padding: 20px 16px 20px 80px;

					&::before{
						left: 30px;	
					}
				}

				@include bp("> 1300px"){
					font-size: rem(25px);
					padding: 24px 16px 24px 100px;

					&::before{
						left: 40px;	
					}
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}

		#nadmenu {
			font-size: rem(14px);
			display: none;
			font-weight: normal;
			padding: 0;
			white-space: nowrap;
		}

		#menu {
			background-color: $menu-bg;
			padding: 0;

			h2.cvi {
				background-color: $color-main;
				padding: 10px 25px 10px 40px;
				font-size: rem(28px);
				color: #000000;
				font-weight: normal;
			}

			.menu-item-collapsed > ul {
				display: none;
			}

			.menu-item-expanded {
				> a .menu-indicator {
					transform: rotate(225deg);
				}

				> ul {
					display: block;
				}
			}

			.menu {
				font-size: rem(16px);
				font-family: $font;
				line-height: 1.2;
				padding: 0;

				a {
					padding: 13px 16px 12px 20px;
					display: block;
					position: relative;
					z-index: 10;
					margin-left: 0;
					text-decoration: none;
				}

				@include bp("> 1100px"){
					font-size: rem(18px);
					
					a {
						padding: 15px 16px 14px 30px;	
					}		
				}

				@include bp("> 1300px"){
					font-size: rem(20px);				
						
					a {
						padding: 17px 16px 16px 40px;	
					}
				}

				ul {
					text-transform: none;
				}

				@include link {
					color: $menu-link-color;
					text-decoration: none;

					&:hover{
						text-decoration: underline;
					}
				}

				.akt a{
					background-color: $barL;

					&:hover{
						text-decoration: none;
					}
				}

				> li {
					border-top: 1px solid #ffffff; 
					border-bottom: 1px solid #ffffff; 
				}

				@include link-all("#osmakt") {
					font-weight: bold;
					background-color: transparent;
					text-decoration: none;
					cursor: default;
					border: 0;
				}

				.menu-indicator {
					width: 16px;
					height: 16px;
					overflow: hidden;
					position: absolute;
					top: .85em;
					left: -27px;
					z-index: 10;
					-webkit-transition: -webkit-transform .25s;
					transition: -ms-transform .25s;
					transition: transform .25s;

					img {
						margin: 0;
						cursor: pointer;
						font-size: rem(14px);
					}
				}

				ul {
					font-size: rem(16px);
					font-family: $sans-serif;
					margin: 0 0 0 -40px;
					padding: 2px 0 12px 0;

					a {
						padding: 1px 15px 0 40px;
						line-height: 1.5;
					}

					.menu-indicator {
						left: 13px;
						top: .23em;
					}

					ul {
						margin: 0;
						padding: 0 0 0 24px;
						position: static;
					}
				}
			}

			li {
				margin: 0;
				padding: 0;
				position: relative;
				display: block;

				li {
					border: 0;
					font-weight: normal;
				}

				// &.akt {
				// 	font-weight: bold;
				// 	text-decoration: none;
				// }
			}
		}
	}

} @else if $bool_dlazdice == true {

	/**********************
	*** DLAŽDICOVÉ MENU ***
	**********************/

	#menu ul {
		@extend %reset-list;
	}

	.nadmenu {
		display: block;
		font-weight: normal;
		padding: 50px 20px 12px 88px;
		font-family: $font;
		font-size: rem(24px);
		line-height: 1.1;
		color: #ffffff;
		font-weight: bold;
		text-transform: uppercase;
		
		@include link {
			font-family: $font;
			color: #ffffff;
			text-decoration: none;
		}

		@include link-over {
			text-decoration: underline;
		}

		a {
			position: relative;	
			
			&::before {
				content: url(images/sipka_back.png);
				width: 35px;
				height: 42px;
				position: absolute;
				top: -.25em;
				left: -60px;
			}
		}
	}

	/**********************
	* MOBILNÍ MENU
	**********************/

	#menu {
		background-color: $menu-bg;
		padding: 20px;

		ul {
			padding-left: 20px;

			a {
				padding: 5px 20px;
				margin-left: -20px;
				display: block;
			}

			@include link {
				color: $menu-link-color;
			}

			@include link-all("#osmakt") {
				background-color: $menu-active-bg;
				color: $menu-active-text;
				text-decoration: none;
			}
		}
	}


	/***********************
	* DESKTOPOVÉ MENU
	***********************/

	@include bp("> #{$menu-tablet-size}") {
		.nadmenu {
			display: block;
		}

		#nadmenu {
			font-size: rem(14px);
			display: none;
			font-weight: normal;
			padding: 0;
			white-space: nowrap;
		}

		#menu {
			background-color: $menu-bg;
			padding: 0 0 50px 0;

			ul { 
				font-size: rem(20px);
				font-family: $font;
				line-height: 1.2;
				padding: 28px 0 5px 0;

				@include link {
					color: $menu-link-color;
					text-decoration: none;
				}

				@include link(".aktodk") {
					color: #232323;
					text-decoration: none;
				}

				@include link-over {
					text-decoration: underline;
				}

				// > li + li {
				// 	border-top: 1px #c7c6c5 solid; 
				// }

				@include link-all("#osmakth") {
					background-color: transparent;
					text-decoration: none;
					cursor: default;
					color: #232323;
					border: 0;
				}

				a {
					padding: 10px 0 10px 0;
					display: block;
					position: relative;
					z-index: 10;
					margin-left: 0;
					text-decoration: none;
				}
			}

			li {
				margin: 0;
				padding: 0 20px 0 88px;
				position: relative;
				display: block;

				&.akt {
					background-color: #ffffff;
				}
			}
		}
	}

}