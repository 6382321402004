/*****************************
* FORMULÁŘE
*****************************/

/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/

%hiding-label {
   line-height: 1.2;
   text-align: left;
   padding: 3px 2px;
   display: block;

   &.label-skryty {
      padding: 0 2px;
      position: relative;

      &::after {
         @extend %fill;
         content: '';
         z-index: 2;
      }
   }
}

/****************************/

/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/

.format {
   background-color: #ffffff;
   color: #000000;
   height: $format-height;
   border-radius: 0;
   line-height: (17 / 14);
   font-size: rem(15px);

   &:-ms-input-placeholder {
      color: inherit !important;
   }

   &::-ms-input-placeholder {
      color: inherit !important;
   }

   &::placeholder {
      color: inherit !important;
      opacity: 1;
   }

   &:focus {
      border-color: $format-border-focus;
   }
}

.format,
fieldset {
   border: 1px $format-border solid;
}

:not(.fvlevo) > .fvyraz #hv {
   font-size: rem(19px);
   height: $format-main-height;
   border-width: 2px;
}

textarea.format {
   min-height: $format-height * 2;
}

select.format[multiple] {
   height: $format-height * 3;
}

.btn {
   background-color: $btn-background;
   border: $btn-border;
   color: $btn-color;
   height: $btn-height;
}

.fbtn .btn {
   padding: 0 2em;
   font-size: rem(16px);

   &:hover {
      text-decoration: underline;
   }
}

/************************************************/

.fkont {
   margin-top: 20px;

   form {
      padding: $form-padding;
   }

   :not(.fvlevo) > .fvyraz label[for='hv'] {
      font-size: rem(16px);
   }
}

.nizky {
   form {
      @include bp('<= 580px') {
         padding-bottom: 60px;
      }
   }
}

.formular,
.fkont form,
.nastaveni {
   background-color: $form-background;
   color: $form-text;

   @include link {
      color: $form-link;
   }
}

//zjistit, kde to vůbec je kromě diskuze
.fcesta,
#{selector-link-all(".fcesta a")} {
   background-color: $th-background;
   color: $th-text;
}

/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/

.aboUzsi {
   padding: 40px 4% 40px 0;
   box-sizing: border-box;
   max-width: 840px;
   width: 100%;
}

.titulformular {
   label {
      @extend %hiding-label;
   }

   .format {
      margin: 2px 0;
      padding: 5px 15px;
      width: 100%;
      box-sizing: border-box;
      height: 67px;
      border: 1px solid $barD;
      background: {
         repeat: no-repeat;
         position: 0 0;
      }
      border-radius: 3px;
      color: #000000;
      font-family: $font2;
      font-size: rem(18px);
      font-weight: 300;
      display: inline-block;

      @include bp('> 500px') {
         width: 67.5%;
      }
   }

   .aboText {
      color: $barL;
      font-family: $font;
      font-weight: bold;
      font-size: rem(18px);
      margin-bottom: 12px;

      @include bp('> 1000px') {
         font-size: rem(22px);
      }

      @include bp('> 1500px') {
         font-size: rem(26px);
      }
   }

   img {
      position: absolute;
      right: 0;
      top: 0;
   }

   .opiste {
      position: relative;
   }

   .captchaformat {
      padding-right: 90px;
   }

   .fbtn,
   .btn {
      clear: none;
      margin: 0;
      display: inline-block;
   }

   .fbtn {
      width: 150px;
      margin-top: 12px;
   }

   @include bp('> 500px') {
      .fbtn {
         width: 29%;
         margin: 0 0 0 2%;
      }
   }

   .btn {
      width: 100%;
      box-sizing: border-box;
      text-decoration: none;
      font-size: rem(18px);
      color: white;
      background: $barD;
      border-color: transparent;
      border-radius: 3px;
      font-family: $font2;
      font-weight: 300;
      height: 67px;
      padding: 8px;

      &:hover {
         text-decoration: underline;
      }
   }

   p {
      padding: 0 0 10px 0;
      margin: 0;
      color: #000000;
      font-family: $font2;
      font-weight: 300;
      font-size: rem(18px);
   }
}

/***************
*	HLEDÁNÍ
***************/

// #hledani {
// 	overflow: hidden;
// 	margin: 20px 0;

// 	@include bp("> 500px") {
// 		float: right;
// 		width: 320px;
// 	}

// 	.pole {
// 		position: relative;
// 		z-index: 5;
// 	}

// 	// label {
// 	// 	@extend %hiding-label;

// 	// 	&.label-skryty::after {
// 	// 		background: transparent url(images/hl_prekryti.svg) repeat 0 0;
// 	// 	}
// 	// }

// 	input:focus {
// 		outline: 0;
// 	}

// 	.format {
// 		width: 100%;
// 		margin: 0;
// 		height: 36px;
// 		border-color: #ffffff;
// 		color: #000000;
// 		padding: 4px 30px 4px 10px;
// 		background: {
// 			repeat: no-repeat;
// 			position: 0 50%;
// 		}
// 	}

// 	.btn {
// 		@extend %reset;
// 		border: 0;
// 		position: absolute;
// 		z-index: 2;
// 		top: 2px;
// 		right: 2px;
// 		height: auto;
// 		border-radius: 0;
// 		background-color: transparent;
// 		color: #000000;
// 	}
// }

// fullscreen vyhledavani
// ----------------------

.lupa {
   display: inline-block;
   position: relative;
   z-index: 10;
   vertical-align: middle;
   margin-left: 16px;

   @include bp('> 600px') {
      margin-left: 0;
   }
}

.fullscreen-search {
   transition: background-color 0.1s ease-out;
   position: fixed;
   top: 0;
   left: -999em;
   z-index: 19000;
   width: 0.1px;
   height: 0.1px;
   overflow: hidden;
   //background: transparent;
   text-align: center;
   font-family: $font;
   background: rgba($barL, 0.9); // todo

   &:target,
   &[data-is-shown='true'] {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: auto;
      height: auto;
      backdrop-filter: blur(8px) grayscale(100%);
   }

   form {
      @extend %reset;
      font-size: rem(16px);
      width: 80%;
      max-width: 1300px;
      margin: 0 auto;
      padding: 10% 0 0 0;
      text-align: left;
      color: #ffffff;
      line-height: 1.4;

      @media screen and (min-height: 35em) {
         padding-top: 14%;
      }

      @media screen and (orientation: portrait) {
         padding-top: 33%;
      }
   }

   .fullscreen-search-close {
      transition: background-color 0.15s ease-out;
      position: absolute;
      top: 0;
      right: 0;
      width: 72px;
      height: 72px;
      cursor: pointer;
      background: transparent
         encode-svg(
            '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><path fill-rule="evenodd" clip-rule="evenodd" fill="#fff" d="M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z"/></svg>'
         )
         no-repeat 50% 50%;
   }

   @include link-over('.fullscreen-search-close') {
      background-color: $barD; // todo
   }

   .fullscreen-search-label {
      font-size: rem(18px);
      display: block;
      margin: 0 0 0.9em 0;
      color: #ffffff;
      text-transform: uppercase;
      font-weight: 600;
   }

   .pole {
      position: relative;
   }

   .fullscreen-search-button {
      @extend %reset-input;
      appearance: none;
      border: 0;
      background: transparent;
      color: #ffffff;
      width: 2.5em;
      height: 2.5em;
      position: absolute;
      right: 0;
      top: 50%;
      margin: -1.3em 0 0 0;

      @include bp('> 1024px') {
         width: 5em;
         height: 5em;
         margin: -2.5em 0 0 0;
      }
   }

   .fullscreen-search-input {
      @extend %reset-input;
      box-sizing: border-box;
      font-size: rem(38px);
      width: 100%;
      font-family: $font;
      background: transparent none !important;
      color: #ffffff;
      outline: 0;
      font-weight: bold;
      padding: 0 1em 0 0;
      border: {
         width: 0 0 2px 0;
         color: rgba(#ffffff, 0.8);
         style: dotted;
      }

      &:focus {
         box-shadow: none !important;
      }

      &::placeholder {
         color: rgba(#ffffff, 0.8);
      }

      @include bp('> 600px') {
         font-size: rem(42px);
      }

      @include bp('> 1024px') {
         font-size: rem(80px);
      }

      @include bp('> 1600px') {
         font-size: rem(86px);
      }
   }
}

.inline-form {
   padding-bottom: 3em;
}

.inline-form #skryvaci,
.inline-form-word {
   display: inline-block;
}

.inline-form-search {
   position: relative;

   input,
   label,
   textarea,
   select {
      font-size: 1rem;
   }
}

.inline-form-word {
   margin: 0.25em 0;
}

.inline-form-input {
   box-sizing: border-box;
   max-width: 12em;
   padding: 2px 10px;
}

.inline-form-datepicker {
   padding-right: 30px;
   background-image: url(/aspinclude/vismoWeb5/html/images/abonent_calendar_2016.svg);
   background-size: 18px auto;
   background-repeat: no-repeat;
   background-position: 98% 48%;
   max-width: 8em;
}

.inline-form-input,
.inline-form-select {
   border-radius: 4px;
   border: 1px #888888 solid;
   height: 1.75em;
}

.inline-form .fbtn {
   margin-bottom: -3em;
}

.inline-form-select {
   max-width: 13em;
   padding-right: 25px;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   text-overflow: ellipsis;
   background: {
      image: encode-svg(
         '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15px" height="15px" viewBox="0 0 15 15" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" fill="#444444" d="M4,0l4,7H0L4,0z M4,15L0,8h8L4,15z"/></svg>'
      );
      position: 100% 50%;
      repeat: no-repeat;
   }
}

.inline-form #prepinac {
   margin-top: 2em;

   @include bp('> 600px') {
      position: absolute;
      left: 0;
      bottom: -2.3em;
      font-size: 1rem;
   }
}
