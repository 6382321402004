/*************************
* ZÁHLAVÍ
*************************/

/*************************
* HLAVA
*************************/

#obalhlava {
   position: relative;
   background-color: $barL;
   color: #ffffff;
   border-bottom: 9px solid $barD;
   max-width: 1920px;
   margin: 0 auto;

   @include link {
      color: #ffffff;
   }
}

#hlava {
   @extend %sf;
   clear: both;
   position: relative;
}

.obalNazev {
   background-color: white;

   .centrovany {
      text-align: center;
   }

   @include bp('> 600px') {
      background: transparent;

      .centrovany {
         text-align: left;
      }

      @include fill;
      height: 117px;
      bottom: auto;
   }

   @include bp('> 800px') {
      bottom: 0;
      height: auto;
   }
}

#znak {
   margin: 0;
   float: left;
}

#nazev {
   display: inline-block;
   background-color: #ffffff;
   padding: 12px 0 12px;

   @include bp('> 600px') {
      float: left;
      display: block;
      width: 280px;
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 16;
   }

   &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 100%;
      width: 190px;
      background-color: #ffffff;
   }

   a {
      z-index: 30;
      display: inline;
      text-decoration: none !important;
      outline-width: 0;
      font-size: 0;

      &::after {
         content: '';
         @extend %fill;
      }
   }

   img {
      font-size: rem(16px);
      line-height: 1;
      color: $barD;
   }

   @include bp('> 1250px') {
      width: 485px;
      padding: 16px 0 12px;
   }
}

/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/
#obrazek {
   display: none;
}

@include bp('> 600px') {
   #obalobrazek {
      position: relative;

      .centrovany {
         position: absolute;
         top: 0;
         bottom: 0;
         z-index: 3;
         left: 50%;
         transform: translateX(-50%);
      }
   }

   #obrazek {
      position: relative;
      display: block;
      min-height: 180px;
   }

   #administraceobrazky {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 4;
   }
}

#blender {
   height: 0;
   max-width: 1920px;
   margin: 0 auto;
   position: relative;
   z-index: 2;
   overflow: hidden;
   padding-bottom: 32%;
   background: #303030 url(images/blenderImages/0.jpg##{$cache-hash}) no-repeat 50% 50%;
   background-size: cover;

   @include bp('> 1400px') {
      padding-bottom: 415px;
   }
}

.blender__buttons {
   position: absolute;
   z-index: 3;
   right: 1em;
   bottom: 1em;
}

.blender__button {
   border-radius: 0;
   -webkit-appearance: none;
   -moz-appearance: none;
   background: transparent none;
   box-shadow: none;

   &:focus {
      box-shadow: none;
   }
}

@keyframes blend-in {
   from {
      display: block;
      opacity: 0;
   }
   to {
      display: block;
      opacity: 1;
   }
}

.blender__item {
   background: {
      position: 50% 50%;
      repeat: no-repeat;
      size: cover;
   }
}

.blender__item {
   @include fill;
}

.blender__item.blender__item--animating {
   animation: blend-in 800ms ease-out;
   animation-fill-mode: forwards;
}

#lista {
   @extend %sf;
   padding: 0;
   background-color: $barD;
   color: white;
   padding: 9px 0 8px;

   #kontakty {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative;
      z-index: 10;
      font-family: $font;
      font-size: rem(14px);

      @include bp('> 900px') {
         font-size: rem(20px);
         margin-right: 20px;
      }

      strong {
         text-transform: uppercase;

         a {
            text-decoration: none;

            &:hover {
               text-decoration: underline;
            }
         }
      }

      li,
      li a {
         display: inline-flex;
         align-items: center;
      }

      li::before {
         margin: 0 5px 0 6px;

         @include bp('> 900px') {
            margin: 0 10px 0 11px;
         }
      }

      li.nodivider::before {
         content: '';
      }
   }

   .centrovany {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;

      @include bp('> 600px') {
         text-align: right;
      }
   }

   ul {
      @extend %reset-list;
      position: relative;

      &.inline {
         display: inline;
      }
   }

   li {
      margin: 0;
      white-space: nowrap;

      &.skip {
         position: absolute;
         display: block;
         top: 0 !important;
         right: 0 !important;
         max-width: 100%;
         padding: 1px;
         border: 0;

         a {
            text-align: center;
            border: 0;
            position: absolute;
            right: 0;
            top: -500px;
            z-index: 0;
            padding: 0 2px;
            background-color: #ffffff;
            color: #000000;
         }

         a:focus,
         a:active {
            z-index: 10 !important;
            position: absolute !important;
            right: 10px;
            top: 5px;
         }
      }
   }
}

#obaljazyky {
   display: inline-block;
   margin: 0 0 0 2px;
   font-family: $font;
   position: relative;
   z-index: 10;
   vertical-align: middle;

   span {
      color: #ffffff;
      font-weight: normal;
   }

   a {
      text-decoration: none;

      &:hover {
         text-decoration: underline;
      }
   }

   li {
      margin: 0 4px;
      display: inline-block;
   }
}

#jazyky {
   .goog-te-menu2-item div,
   .goog-te-menu2-item:link div,
   .goog-te-menu2-item:visited div,
   .goog-te-menu2-item:active div {
      background-color: transparent;
   }

   .goog-te-menu2-item:hover div {
      background-color: transparent;
   }

   .goog-te-menu2-item-selected div,
   .goog-te-menu2-item-selected:link div,
   .goog-te-menu2-item-selected:visited div,
   .goog-te-menu2-item-selected:hover div,
   .goog-te-menu2-item-selected:active div {
      font-weight: normal;
   }

   a > div {
      padding: 0;

      .indicator {
         display: none;
      }
   }

   .text {
      font-size: 0;

      &::before {
         font-size: rem(18px);
         font-weight: 300;
      }

      &.cs::before {
         content: 'CS';
      }

      &.en::before {
         content: 'EN';
      }

      &.de::before {
         content: 'DE';
      }

      &.ru::before {
         content: 'RU';
      }
   }

   div {
      display: inline;
   }
}
