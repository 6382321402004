@charset "UTF-8";
/*!
  Author: WEBHOUSE, s. r. o.
  Version: 1.2
*/
/** PROMĚNNÉ PRO NASTAVENÍ ECCO **/
/*********************************/
/*! ECCO SASS knihovna
    @version: 1.0.4 */
/*****************************************
* ECCO - GLOBAL VARIABLES
*****************************************/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*****************************************
* ECCO - PLACEHOLDERS
*****************************************/
#stranka #kalakci .mesice, #titul .zalozky li, #keskryti, .fullscreen-search form, .obrodkazy li, body, #menu ul, #menu1 ul, #temata ul, #temata li, .bezjs ul, .zalozky ul, .titulodkazy .ui, .obrodkazy .ui, .dok ul, #lista ul {
  margin: 0;
  padding: 0;
}

#menu ul, #menu1 ul, #temata ul, #temata li, .bezjs ul, .zalozky ul, .titulodkazy .ui, .obrodkazy .ui, .dok ul, #lista ul {
  list-style-type: none;
}

.fullscreen-search .fullscreen-search-input, .fullscreen-search .fullscreen-search-button {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
}
.fullscreen-search .fullscreen-search-input:focus, .fullscreen-search .fullscreen-search-button:focus {
  box-shadow: none;
}

.titulformular label.label-skryty::after, #nazev a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#kalakci .calcells td .desc {
  position: absolute;
  left: -9999em;
  top: 0;
  width: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
  outline: 0;
}

.odkazy .ui li li li::before, .aktuality2 li.bezobr li li::before, .utvary .ui li.o li li::before, .kategorie .ui li li li::before, .utvary .ui li li li::before, .galerie .ui li li li::before, #stred,
#vpravo,
#vlevo {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.zalozky ul::after, #lista::after, #hlava::after, .centrovany::after {
  content: "";
  display: table;
  font-size: 0;
  clear: both;
}

/*****************************************
* ECCO - FLEXBOX
*****************************************/
/***********************************
/* HODNOTY BITŮ PRO NASTAVENÍ WEBU 
***********************************/
/*************************
* GLOBÁLNÍ PROMĚNNÉ
*************************/
/************************/
/*************************
* PROMĚNNÉ KOMPONENT
*************************/
/** TYPOGRAFIE **/
/** FORMULÁŘE **/
/** TABULKY **/
/** SEZNAMY **/
/** AKCE **/
/** KALENDÁŘE **/
/** Hodnoty je lepší zadávat v em **/
/** MENU **/
/** ZÁLOŽKY **/
/** PATA **/
/************************/
/***********************************************************
* PROMĚNNÉ BREAKPOINTŮ PRO SNADNĚJŠÍ ZADÁVÁNÍ MIXINU "BP"
***********************************************************/
/**********************************************************/
html {
  line-height: 1.375;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}
@media screen and (min-width: 601px) {
  html {
    font-size: 16px;
  }
}

body.editor {
  background-color: #ffffff;
}

.editor h3 {
  font-weight: normal;
  margin: 10px 0;
  padding: 0;
  font-family: Raleway;
  line-height: 1.2;
}

body,
.editor,
body.editor,
body.editor td,
body.editor th {
  font-size: 1em;
}

body,
.automat {
  color: #000000;
}

a,
a:link,
a:visited {
  color: #232323;
  text-decoration: underline;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

hr,
hr.oddelovac {
  color: #dddddd;
  background-color: #dddddd;
}

body,
input,
button,
textarea,
select {
  font-family: Arial, Helvetica, sans-serif;
}

.zvyrazneni,
.vystraha,
.strlistovani strong {
  color: #BD013E;
  background-color: #ffffff;
  padding: 0 3px;
}

.vystraha {
  border-radius: 3px;
}

.zvyrazneni2 {
  background-color: #303030;
  color: #ffffff;
  padding: 0 2px;
}

strong.zprava {
  font-size: 1.1em;
}

/*******************
* NADPISY
*******************/
h1.cvi {
  font-size: 1rem;
  padding: 0;
  line-height: 1.1;
  margin: 0 12px 0 20px;
  font-family: Raleway;
  font-weight: 500;
  text-transform: uppercase;
  color: #4c6997;
  float: left;
}
h1.cvi strong {
  font-size: 3rem;
  font-weight: 700;
}
h1.cvi span {
  display: block;
  width: 10.5em;
  line-height: 1.3;
}
@media screen and (min-width: 1251px) {
  h1.cvi {
    padding: 14px 0 0 0;
    margin: 0 0 0 16px;
  }
  h1.cvi strong {
    font-size: 3.5625rem;
  }
  h1.cvi span {
    display: inline-block;
    line-height: 1.5;
    margin-left: 18px;
    letter-spacing: 0.05em;
  }
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
#prohlaseni h3,
h4 {
  font-family: Raleway;
}

h2.nadpis, h2.cvi {
  text-transform: uppercase;
  font-size: 1.75rem;
  font-family: Raleway;
  font-weight: bold;
  margin: 0;
  padding: 16px 0;
}
@media screen and (min-width: 1001px) {
  h2.nadpis, h2.cvi {
    font-size: 2.125rem;
  }
}
@media screen and (min-width: 1501px) {
  h2.nadpis, h2.cvi {
    font-size: 2.5rem;
  }
}

h2.cvi,
.poz h2.cist {
  font-size: 2.5rem;
  color: #25426f;
  margin: 0;
  padding: 8px 0;
  line-height: 1.2;
}

#zahlavi h2.cvi {
  margin: 0 0 20px 0;
  padding: 0;
}

#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
  font-size: 2.125rem;
  color: #25426f;
}
@media screen and (min-width: 1501px) {
  #zahlavi h2.cvi,
  #zalozkynadpis h2.cvi {
    font-size: 2.5rem;
  }
}

.editor h3, h3.cvi, h3.ud, #prohlaseni h3 {
  font-size: 2.0625rem;
  font-weight: normal;
  margin: 25px 0 5px 0;
  padding: 8px 20px 4px 0;
  line-height: 1.2;
  color: #25426f;
}

h4 {
  font-size: 1.625rem;
  line-height: 1.2;
  color: #25426f;
}

h4.urd-list {
  font-size: 1.5625rem;
  padding-top: 0.75em;
  float: none;
  border-top: 3px #cccccc solid;
  padding-right: 7em;
  color: #25426f;
  font-weight: bold;
}
h4.urd-list + .udz.urd-list {
  margin-top: -1.6em;
  margin-bottom: 2em;
  float: none;
  text-align: right;
}
@media screen and (max-width: 1023px) {
  h4.urd-list {
    padding-right: 0;
  }
  h4.urd-list + .udz.urd-list {
    margin-top: 1em;
  }
}

h5 {
  font-size: 1.1em;
}

/*************************
* ŠABLONY
*************************/
/*************************
* DOKUMENTY
*************************/
#pozicovani .cards .card {
  margin-top: 0;
  overflow: hidden;
  flex-grow: 1;
}
#pozicovani .cards .card img {
  float: none;
  display: table;
  margin: 0;
  width: 100%;
}
#pozicovani .cards .card strong a {
  font-size: 1.1875rem;
  font-family: Raleway;
  font-weight: 500;
  color: #4c6997;
  text-transform: uppercase;
  text-decoration: underline;
}
@media screen and (min-width: 801px) {
  #pozicovani .cards .card strong a {
    font-size: 1.4375rem;
  }
}
#pozicovani .cards .card strong a:hover {
  color: #25426f;
  text-decoration: none;
}
#pozicovani .cards .card .dok-datum {
  margin-top: 10px;
}
#pozicovani .cards .card .dok-upoutavka {
  display: block;
  overflow: hidden;
  margin: 0;
}
#pozicovani .cards .card .dok-upoutavka img {
  margin-bottom: 10px;
}
#pozicovani .cards .card .dok-nazev {
  display: block;
}
#pozicovani .cards .card .popis, #pozicovani .cards .card .vd-priloha {
  margin-top: 12px;
  font-family: "Open sans";
  font-size: 1rem;
  font-weight: 300;
  color: #000000;
  display: block;
  line-height: 1.8;
}
#pozicovani .cards .card .vd-priloha {
  margin-bottom: 12px;
  color: #4c6997;
  line-height: 1.375;
}
#pozicovani .cards .card .odkazVice {
  padding-right: 20px;
  position: absolute;
  right: 24px;
  bottom: 26px;
  color: #4c6997;
}
#pozicovani .cards .card .odkazVice::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 14px;
  background-image: url(images/sipkaDok.png), url(images/sipkaLinkaDok.png);
  background-repeat: no-repeat;
  background-position: left center;
  transition: background-position 0.5s;
}
#pozicovani .cards .card .odkazVice:hover::after {
  background-image: url(images/sipkaDokHover.png), url(images/sipkaLinkaDok.png);
  background-position: right center, left center;
}

/**************************
* OBECNÉ
**************************/
html {
  touch-action: manipulation;
}

html,
body {
  background-color: #ffffff;
}

body {
  text-align: center;
}

::-moz-selection {
  background-color: #228be6;
  color: #ffffff;
}

::selection {
  background-color: #228be6;
  color: #ffffff;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.patalogo):not(.expandable):not(.menu-link):not(.mouse-focus):focus {
  border-radius: 3px;
  box-shadow: 0 0 0 2000px #303030 inset, 0 0 0 3px #303030 !important;
  color: #ffffff !important;
  outline: 0;
}

img {
  color: inherit;
}

/*************************
* HLAVNÍ OBSAH
*************************/
.section {
  padding-top: 3em;
  padding-bottom: 3em;
}

#navigace, #navigace2, #hlobsah {
  display: block;
}

.centrovany {
  width: 94%;
  max-width: 1540px;
  margin: 0 auto;
  text-align: left;
}

#stred,
#vpravo,
#vlevo {
  width: 100%;
  margin-bottom: 45px;
}

#vpravo,
#vlevo {
  word-wrap: break-word;
}

#stranka #stred {
  padding: 15px 0;
  font-family: "Open sans";
  font-size: 1.0625rem;
}

.stredUzsi {
  width: 100%;
  max-width: 770px;
}
@media screen and (min-width: 801px) {
  .stredUzsi {
    float: left;
  }
}

.sirsi-stred .stredUzsi {
  float: none;
  max-width: 100%;
}

/*************************************
* FLEX LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/
@media screen and (min-width: 801px) {
  #stranka #telo {
    display: flex;
    justify-content: space-between;
  }
  #stred {
    width: 59%;
    order: 1;
  }
  #vlevo {
    width: 36%;
  }
}
@media screen and (min-width: 1001px) {
  #stred {
    width: 63%;
  }
  #vlevo {
    width: 32%;
  }
}
@media screen and (min-width: 1201px) {
  #stred {
    width: 68.7012987013%;
  }
  #vlevo {
    width: 27.7922077922%;
  }
}
/************************
* PRVKY STRÁNKY
************************/
#stranka #obalcelek {
  background-color: #f3f3f3;
}
@media screen and (min-width: 1001px) {
  #stranka #obalcelek {
    background-image: url(images/bg_strankaLT.png), url(images/bg_strankaRB.png);
    background-repeat: no-repeat;
    background-position: center top, center bottom;
  }
}

.cesta {
  font-size: 1rem;
  padding: 22px 0 18px 0;
  margin: 0;
  position: relative;
  font-family: Raleway;
}
@media screen and (min-width: 601px) {
  .cesta {
    font-size: 1.125rem;
  }
}
.cesta, .cesta a {
  color: #000000;
}
.cesta strong {
  font-weight: normal;
}

.dalsi {
  font-size: 1.125rem;
  margin: 15px 0 0 0;
  padding: 5px 0 0 0;
  text-align: left;
  font-family: Raleway;
}
.dalsi a {
  display: inline-block;
  padding: 10px 30px;
}
.dalsi a,
.dalsi a:link,
.dalsi a:visited {
  text-decoration: none;
}
.dalsi a:hover,
.dalsi a:focus,
.dalsi a:active {
  text-decoration: underline;
}

a.tlacitko {
  display: inline-block;
  font-family: "Open sans";
  font-size: 1.125rem;
  font-weight: 300;
  color: white;
  background: #25426f;
  padding: 1em 2.25em;
  border-radius: 3px;
}

/************************
* ZÁPATÍ
************************/
#nadpatou {
  background: #25426f;
  padding: 40px 0 50px;
  font-size: 1.125rem;
  font-family: "Open sans";
  font-weight: 300;
}
#nadpatou,
#nadpatou h2,
#nadpatou h3,
#nadpatou a, #nadpatou a:link, #nadpatou a:visited {
  color: #ffffff;
}
#nadpatou h2 {
  padding-bottom: 0;
}
#nadpatou strong {
  font-weight: bold;
}
#nadpatou #kontakt {
  max-width: 1100px;
  float: left;
  width: 100%;
  line-height: 1.6;
}
#nadpatou .np-co {
  display: block;
}
@media screen and (min-width: 901px) {
  #nadpatou .np-co {
    float: left;
    box-sizing: border-box;
    padding-right: 3%;
  }
  #nadpatou .co-1 {
    width: 38%;
  }
  #nadpatou .co-2 {
    width: 31%;
  }
  #nadpatou .co-3 {
    width: 31%;
    padding-right: 0;
  }
}

.centKrVys {
  text-align: center;
  padding: 44px 0 4px;
}

#obalpata {
  background-color: #ffffff;
  min-width: 320px;
}

#pata {
  text-align: center;
  padding: 22px 16px 28px;
  color: #444444;
  box-sizing: border-box;
  font-size: 0.875rem;
}
#pata * {
  line-height: 2.2;
  border: 0;
}
#pata li + li::before {
  content: "|";
  margin: 0 8px 0 0;
}
#pata .partWebmaster1::before {
  content: "|";
  margin: 0 10px 0 0;
}
#pata a,
#pata a:link,
#pata a:visited {
  color: #444444;
  text-decoration: underline;
}
#pata a:hover,
#pata a:focus,
#pata a:active {
  text-decoration: none;
}
#pata .patalogo {
  padding: 1px 6px;
  margin-right: 11px;
}
#pata #pocitadlo {
  margin: 0 !important;
  padding-bottom: 0 !important;
}
#pata .webmaster,
#pata .inline {
  display: inline-block;
  margin: 0;
  padding: 0 0 0 3px;
}
#pata .webmaster li,
#pata .inline li {
  padding: 0 5px;
}
#pata .webmaster li a,
#pata .inline li a {
  margin: 0 0 0 1px;
}
#pata .webmaster {
  display: block;
}
#stranka #pata .webmaster {
  margin-top: 2px;
}
#pata .webmaster .partWebmaster {
  display: inline-block;
  margin-left: 5px;
}
#pata ul.inline li {
  display: inline-block;
}

@media screen and (max-width: 499px) {
  .partWebmaster1 {
    display: block !important;
    margin-top: 2px;
  }
  .partWebmaster1::before {
    content: "" !important;
    margin: 0 !important;
  }
}
#unpobtekane {
  margin-left: 0 !important;
}

.obsah .editor table img {
  max-width: 140px !important;
  margin: 4px !important;
}

.utvary .ui .o .phone .comma::after, .utvary .ui .o .person-type + .email .comma::after {
  content: "";
  display: table;
  height: 0;
  clear: both;
}

/*************************
* ZÁHLAVÍ
*************************/
/*************************
* HLAVA
*************************/
#obalhlava {
  position: relative;
  background-color: #4c6997;
  color: #ffffff;
  border-bottom: 9px solid #25426f;
  max-width: 1920px;
  margin: 0 auto;
}
#obalhlava a,
#obalhlava a:link,
#obalhlava a:visited {
  color: #ffffff;
}

#hlava {
  clear: both;
  position: relative;
}

.obalNazev {
  background-color: white;
}
.obalNazev .centrovany {
  text-align: center;
}
@media screen and (min-width: 601px) {
  .obalNazev {
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 117px;
    bottom: auto;
  }
  .obalNazev .centrovany {
    text-align: left;
  }
}
@media screen and (min-width: 801px) {
  .obalNazev {
    bottom: 0;
    height: auto;
  }
}

#znak {
  margin: 0;
  float: left;
}

#nazev {
  display: inline-block;
  background-color: #ffffff;
  padding: 12px 0 12px;
}
@media screen and (min-width: 601px) {
  #nazev {
    float: left;
    display: block;
    width: 280px;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 16;
  }
}
#nazev::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 190px;
  background-color: #ffffff;
}
#nazev a {
  z-index: 30;
  display: inline;
  text-decoration: none !important;
  outline-width: 0;
  font-size: 0;
}
#nazev a::after {
  content: "";
}
#nazev img {
  font-size: 1rem;
  line-height: 1;
  color: #25426f;
}
@media screen and (min-width: 1251px) {
  #nazev {
    width: 485px;
    padding: 16px 0 12px;
  }
}

/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/
#obrazek {
  display: none;
}

@media screen and (min-width: 601px) {
  #obalobrazek {
    position: relative;
  }
  #obalobrazek .centrovany {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
  }
  #obrazek {
    position: relative;
    display: block;
    min-height: 180px;
  }
  #administraceobrazky {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
  }
}
#blender {
  height: 0;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding-bottom: 32%;
  background: #303030 url(images/blenderImages/0.jpg#uo1kkct) no-repeat 50% 50%;
  background-size: cover;
}
@media screen and (min-width: 1401px) {
  #blender {
    padding-bottom: 415px;
  }
}

.blender__buttons {
  position: absolute;
  z-index: 3;
  right: 1em;
  bottom: 1em;
}

.blender__button {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
}
.blender__button:focus {
  box-shadow: none;
}

@keyframes blend-in {
  from {
    display: block;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}
.blender__item {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.blender__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.blender__item.blender__item--animating {
  animation: blend-in 800ms ease-out;
  animation-fill-mode: forwards;
}

#lista {
  padding: 0;
  background-color: #25426f;
  color: white;
  padding: 9px 0 8px;
}
#lista #kontakty {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 10;
  font-family: Raleway;
  font-size: 0.875rem;
}
@media screen and (min-width: 901px) {
  #lista #kontakty {
    font-size: 1.25rem;
    margin-right: 20px;
  }
}
#lista #kontakty strong {
  text-transform: uppercase;
}
#lista #kontakty strong a {
  text-decoration: none;
}
#lista #kontakty strong a:hover {
  text-decoration: underline;
}
#lista #kontakty li,
#lista #kontakty li a {
  display: inline-flex;
  align-items: center;
}
#lista #kontakty li::before {
  margin: 0 5px 0 6px;
}
@media screen and (min-width: 901px) {
  #lista #kontakty li::before {
    margin: 0 10px 0 11px;
  }
}
#lista #kontakty li.nodivider::before {
  content: "";
}
#lista .centrovany {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (min-width: 601px) {
  #lista .centrovany {
    text-align: right;
  }
}
#lista ul {
  position: relative;
}
#lista ul.inline {
  display: inline;
}
#lista li {
  margin: 0;
  white-space: nowrap;
}
#lista li.skip {
  position: absolute;
  display: block;
  top: 0 !important;
  right: 0 !important;
  max-width: 100%;
  padding: 1px;
  border: 0;
}
#lista li.skip a {
  text-align: center;
  border: 0;
  position: absolute;
  right: 0;
  top: -500px;
  z-index: 0;
  padding: 0 2px;
  background-color: #ffffff;
  color: #000000;
}
#lista li.skip a:focus,
#lista li.skip a:active {
  z-index: 10 !important;
  position: absolute !important;
  right: 10px;
  top: 5px;
}

#obaljazyky {
  display: inline-block;
  margin: 0 0 0 2px;
  font-family: Raleway;
  position: relative;
  z-index: 10;
  vertical-align: middle;
}
#obaljazyky span {
  color: #ffffff;
  font-weight: normal;
}
#obaljazyky a {
  text-decoration: none;
}
#obaljazyky a:hover {
  text-decoration: underline;
}
#obaljazyky li {
  margin: 0 4px;
  display: inline-block;
}

#jazyky .goog-te-menu2-item div,
#jazyky .goog-te-menu2-item:link div,
#jazyky .goog-te-menu2-item:visited div,
#jazyky .goog-te-menu2-item:active div {
  background-color: transparent;
}
#jazyky .goog-te-menu2-item:hover div {
  background-color: transparent;
}
#jazyky .goog-te-menu2-item-selected div,
#jazyky .goog-te-menu2-item-selected:link div,
#jazyky .goog-te-menu2-item-selected:visited div,
#jazyky .goog-te-menu2-item-selected:hover div,
#jazyky .goog-te-menu2-item-selected:active div {
  font-weight: normal;
}
#jazyky a > div {
  padding: 0;
}
#jazyky a > div .indicator {
  display: none;
}
#jazyky .text {
  font-size: 0;
}
#jazyky .text::before {
  font-size: 1.125rem;
  font-weight: 300;
}
#jazyky .text.cs::before {
  content: "CS";
}
#jazyky .text.en::before {
  content: "EN";
}
#jazyky .text.de::before {
  content: "DE";
}
#jazyky .text.ru::before {
  content: "RU";
}
#jazyky div {
  display: inline;
}

/*************************
* SEZNAMY
*************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/************************/
.odkazy .ui li li li::before, .aktuality2 li.bezobr li li::before, .utvary .ui li.o li li::before, .kategorie .ui li li li::before, .utvary .ui li li li::before, .galerie .ui li li li::before {
  position: relative;
  top: -0.08em;
  margin-right: 7px;
  display: inline-block;
  content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='7px' height='7px' viewBox='0 0 7 7'%3E%3Cpath fill='%23BCBCBC' stroke='%23BCBCBC' stroke-miterlimit='10' d='M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z'/%3E%3C/svg%3E");
  vertical-align: middle;
  line-height: 0;
  overflow: hidden;
}
li.u, li.typsouboru {
  list-style-type: none !important;
}
li.u::before, li.typsouboru::before {
  content: normal !important;
}
.odkazy ol li::before::before {
  content: normal !important;
}

dl dt {
  font-size: 1.1875rem;
  color: #25426f;
  line-height: 1.1;
}

dl.kontakty .utvary {
  padding-top: 4px;
}

.ui li > strong:first-child {
  font-family: Raleway;
  font-size: 1.25rem;
}
.ui li > strong:first-child a,
.ui li > strong:first-child a:link,
.ui li > strong:first-child a:visited {
  color: #25426f;
}
.utvary .ui li > strong:first-child, .souvisejiciodkazy .ui li > strong:first-child {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: bold;
}
.utvary .ui li > strong:first-child a,
.utvary .ui li > strong:first-child a:link,
.utvary .ui li > strong:first-child a:visited, .souvisejiciodkazy .ui li > strong:first-child a,
.souvisejiciodkazy .ui li > strong:first-child a:link,
.souvisejiciodkazy .ui li > strong:first-child a:visited {
  color: #232323;
}
.ui li > strong:first-child img {
  font-size: 0.875rem;
}
.utvary .ui li li > strong:first-child, .bodkazy .ui li li > strong:first-child, .souvisejiciodkazy .ui li li > strong:first-child {
  font-weight: normal;
}
.ui ul li > strong:first-child {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
}
.ui ul li > strong:first-child a,
.ui ul li > strong:first-child a:link,
.ui ul li > strong:first-child a:visited {
  color: #232323;
}
.ui li {
  clear: none;
  padding: 0;
  margin: 0 0 5px 0;
}
.ui li strong {
  font-weight: normal;
  line-height: 1.2;
}
dl.kontakty .utvary .ui li strong + div, #stromutvaru .utvary .ui li strong + div, .odkazy .ui li strong + div {
  padding: 0;
  color: #666666;
  line-height: 1.4;
  font-style: italic;
}
dl.kontakty .utvary .ui li strong + div {
  margin-bottom: 10px;
}
.ui li .ui {
  padding-top: 0;
  padding-bottom: 10px;
}
.ui li .ui .ui {
  padding-bottom: 0;
}
.ui li .ui .ui .ui {
  padding-left: 18px;
}
.ui li li {
  margin: 5px 0;
}
.ui li li::after {
  content: normal;
}
.ui ol li {
  padding-left: 0;
}
.ui div {
  font-weight: normal;
  margin-bottom: 2px;
  margin-top: 6px;
  padding-top: 0;
  padding-bottom: 0;
}

dl.kontakty .utvary ul.ui {
  margin-left: 0;
  margin-bottom: 15px;
}
dl.kontakty .utvary li strong {
  font-size: 0.9375rem;
  font-family: Arial, Helvetica, sans-serif;
}
dl.kontakty .utvary li strong a,
dl.kontakty .utvary li strong a:link,
dl.kontakty .utvary li strong a:visited {
  color: #232323;
}

/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/
#isvs .hodnota li::before {
  content: normal !important;
}

/*********************
* VÝPISY DOKUMENTŮ
*********************/
.dok li {
  display: block;
  margin: 20px 0;
  padding: 0;
}
.dok li:first-child {
  margin-top: 8px;
}
.dok .n5-akce-typ,
.dok .dok-doplnek,
.dok .ktg,
.dok strong + span,
.dok .vd-priloha {
  font-size: 0.9375rem;
  color: #444444;
}
.dok .n5-akce-typ a,
.dok .n5-akce-typ a:link,
.dok .n5-akce-typ a:visited,
.dok .dok-doplnek a,
.dok .dok-doplnek a:link,
.dok .dok-doplnek a:visited,
.dok .ktg a,
.dok .ktg a:link,
.dok .ktg a:visited,
.dok strong + span a,
.dok strong + span a:link,
.dok strong + span a:visited,
.dok .vd-priloha a,
.dok .vd-priloha a:link,
.dok .vd-priloha a:visited {
  color: #444444;
}
.dok .vd-priloha {
  font-family: Arial, Helvetica, sans-serif;
  white-space: nowrap;
}
.dok strong {
  font-weight: normal;
}
.dok strong img {
  margin: 3px 20px 6px 0;
  float: left;
}
.dok strong a {
  font-size: 1.375rem;
  font-family: Raleway;
  color: #25426f;
  line-height: 1.375;
}

#zpravodajstvi ul.inline {
  padding: 0;
  margin: 20px 0;
}

#kalendarAkci .dok li.u {
  padding-left: 150px;
}
#kalendarAkci .dok li.u strong img {
  margin-left: -150px;
}

/** DOKUMENTY NA TITULCE **/
.dokTitulka {
  background: #F3F3F3 url(images/bg_dok.png) no-repeat center top;
  padding: 62px 0 47px;
}

#pozicovani {
  overflow: hidden;
}
#pozicovani .poz {
  margin-bottom: 40px;
}
#pozicovani .cards ul {
  display: flex;
  flex-wrap: wrap;
}
#pozicovani .cards li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  margin-top: 0;
  box-sizing: border-box;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fdfdfd;
  padding: 26px 24px 80px;
  position: relative;
}
@media screen and (max-width: 599px) {
  #pozicovani .cards li {
    width: 100%;
  }
}
@media screen and (min-width: 600px) and (max-width: 999px) {
  #pozicovani .cards li {
    width: 49.2%;
    margin-left: 1.6%;
  }
  #pozicovani .cards li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1000px) {
  #pozicovani .cards li {
    width: 32.2666666667%;
    margin-left: 1.6%;
  }
  #pozicovani .cards li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 650px) {
  #pozicovani .skryty {
    display: none;
  }
}

/*******************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ	
*******************************/
.obrodkazy li {
  display: block;
  margin-bottom: 13px;
  background-image: none;
  position: relative;
}
.obrodkazy li::before {
  content: normal !important;
}
.obrodkazy a {
  font-size: 1.25rem;
  font-family: Arial, Helvetica, sans-serif;
  background-repeat: no-repeat;
  background-position: 0 50%;
  padding-left: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 48px;
}
.obrodkazy strong {
  font-weight: normal;
}
.obrodkazy a,
.obrodkazy a:link,
.obrodkazy a:visited {
  text-decoration: none;
}
.obrodkazy a:hover,
.obrodkazy a:focus,
.obrodkazy a:active {
  text-decoration: underline;
}

/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/
#vypisakci h3.cvi {
  font-size: 1.5625rem;
  margin-bottom: 10px;
}
#vypisakci .dok li {
  display: flex;
  margin: 14px 0;
  padding-bottom: 14px;
  width: 100%;
}
#vypisakci .dok li strong a,
#vypisakci .dok li strong a:link,
#vypisakci .dok li strong a:visited {
  color: orange;
}
#vypisakci .dok .datum,
#vypisakci .dok .prazdnyDatum {
  width: 5.52em;
  text-align: center;
  flex-shrink: 0;
  padding: 0.26em 0;
  font-family: Raleway;
}
#vypisakci .dok .den,
#vypisakci .dok .mesic {
  display: block;
  line-height: 1.2;
}
#vypisakci .dok .den {
  font-size: 1.375rem;
  font-weight: bold;
  background: gold;
  color: #101010;
  padding: 0.3em 0;
}
#vypisakci .dok .mesic {
  font-size: 1rem;
  background: #444444;
  color: #ffffff;
  text-transform: lowercase;
  padding: 0.5em 0;
}
#vypisakci .dok .denPredlozka,
#vypisakci .dok .rok,
#vypisakci .dok .den .tecka {
  position: absolute;
  left: -9999px;
  right: 9990px;
}
#vypisakci .dok .prazdnyDatum {
  background: transparent;
}
#vypisakci .dok .obsahAkce {
  padding-right: 15px;
  padding-left: 15px;
  flex-grow: 1;
}

/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/
.titulodkazy li {
  display: block;
  background-image: none;
  margin: 4px 0;
  padding: 0;
  position: relative;
}
.titulodkazy li::before {
  content: normal !important;
}
.titulodkazy li a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
}
.titulodkazy li strong:first-child a,
.titulodkazy li strong:first-child a:link,
.titulodkazy li strong:first-child a:visited {
  color: #232323;
}
.titulodkazy li div {
  margin: 2px 0 0 0;
}
.titulodkazy strong {
  font-weight: normal;
}

.dok li.urd-line {
  position: relative;
  margin: 0;
  padding: 15px 20px;
}
.dok li.urd-line:nth-child(even) {
  background-color: #ededed;
}
.dok li.urd-line::after {
  content: normal;
}
.dok li.urd-line .urd-left {
  position: relative;
}
.dok li.urd-line strong a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}
@media screen and (min-width: 801px) {
  .dok li.urd-line {
    display: table;
    box-sizing: border-box;
    width: 100%;
  }
  .dok li.urd-line .urd-left,
  .dok li.urd-line .urd-right {
    display: table-cell;
  }
  .dok li.urd-line .urd-left {
    padding-right: 1.5em;
  }
  .dok li.urd-line .urd-right {
    width: 13em;
  }
}

.bodkazy .ui li > strong:first-child a,
.bodkazy .ui li > strong:first-child a:link,
.bodkazy .ui li > strong:first-child a:visited {
  color: #444444;
}

/**********************
*** DLAŽDICOVÉ MENU ***
**********************/
/*****************************
* FORMULÁŘE
*****************************/
/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/
.titulformular label {
  line-height: 1.2;
  text-align: left;
  padding: 3px 2px;
  display: block;
}
.titulformular label.label-skryty {
  padding: 0 2px;
  position: relative;
}
.titulformular label.label-skryty::after {
  content: "";
  z-index: 2;
}

/****************************/
/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/
.format {
  background-color: #ffffff;
  color: #000000;
  height: 44px;
  border-radius: 0;
  line-height: 1.2142857143;
  font-size: 0.9375rem;
}
.format:-ms-input-placeholder {
  color: inherit !important;
}
.format::-ms-input-placeholder {
  color: inherit !important;
}
.format::placeholder {
  color: inherit !important;
  opacity: 1;
}
.format:focus {
  border-color: #437DCC;
}

.format,
fieldset {
  border: 1px #25426f solid;
}

:not(.fvlevo) > .fvyraz #hv {
  font-size: 1.1875rem;
  height: 44px;
  border-width: 2px;
}

textarea.format {
  min-height: 88px;
}

select.format[multiple] {
  height: 132px;
}

.btn {
  background-color: #25426f;
  border: 0;
  color: #ffffff;
  height: 44px;
}

.fbtn .btn {
  padding: 0 2em;
  font-size: 1rem;
}
.fbtn .btn:hover {
  text-decoration: underline;
}

/************************************************/
.fkont {
  margin-top: 20px;
}
.fkont form {
  padding: 21px 36px 23px 36px;
}
.fkont :not(.fvlevo) > .fvyraz label[for=hv] {
  font-size: 1rem;
}

@media screen and (max-width: 580px) {
  .nizky form {
    padding-bottom: 60px;
  }
}

.formular,
.fkont form,
.nastaveni {
  background-color: #ffffff;
  color: #202020;
}
.formular a,
.formular a:link,
.formular a:visited,
.fkont form a,
.fkont form a:link,
.fkont form a:visited,
.nastaveni a,
.nastaveni a:link,
.nastaveni a:visited {
  color: #202020;
}

.fcesta,
.fcesta a, .fcesta a:link, .fcesta a:visited, .fcesta a:hover, selector:focus, selector:active {
  background-color: #25426f;
  color: #ffffff;
}

/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/
.aboUzsi {
  padding: 40px 4% 40px 0;
  box-sizing: border-box;
  max-width: 840px;
  width: 100%;
}

.titulformular .format {
  margin: 2px 0;
  padding: 5px 15px;
  width: 100%;
  box-sizing: border-box;
  height: 67px;
  border: 1px solid #25426f;
  background-repeat: no-repeat;
  background-position: 0 0;
  border-radius: 3px;
  color: #000000;
  font-family: "Open sans";
  font-size: 1.125rem;
  font-weight: 300;
  display: inline-block;
}
@media screen and (min-width: 501px) {
  .titulformular .format {
    width: 67.5%;
  }
}
.titulformular .aboText {
  color: #4c6997;
  font-family: Raleway;
  font-weight: bold;
  font-size: 1.125rem;
  margin-bottom: 12px;
}
@media screen and (min-width: 1001px) {
  .titulformular .aboText {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 1501px) {
  .titulformular .aboText {
    font-size: 1.625rem;
  }
}
.titulformular img {
  position: absolute;
  right: 0;
  top: 0;
}
.titulformular .opiste {
  position: relative;
}
.titulformular .captchaformat {
  padding-right: 90px;
}
.titulformular .fbtn,
.titulformular .btn {
  clear: none;
  margin: 0;
  display: inline-block;
}
.titulformular .fbtn {
  width: 150px;
  margin-top: 12px;
}
@media screen and (min-width: 501px) {
  .titulformular .fbtn {
    width: 29%;
    margin: 0 0 0 2%;
  }
}
.titulformular .btn {
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 1.125rem;
  color: white;
  background: #25426f;
  border-color: transparent;
  border-radius: 3px;
  font-family: "Open sans";
  font-weight: 300;
  height: 67px;
  padding: 8px;
}
.titulformular .btn:hover {
  text-decoration: underline;
}
.titulformular p {
  padding: 0 0 10px 0;
  margin: 0;
  color: #000000;
  font-family: "Open sans";
  font-weight: 300;
  font-size: 1.125rem;
}

/***************
*	HLEDÁNÍ
***************/
.lupa {
  display: inline-block;
  position: relative;
  z-index: 10;
  vertical-align: middle;
  margin-left: 16px;
}
@media screen and (min-width: 601px) {
  .lupa {
    margin-left: 0;
  }
}

.fullscreen-search {
  transition: background-color 0.1s ease-out;
  position: fixed;
  top: 0;
  left: -999em;
  z-index: 19000;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  text-align: center;
  font-family: Raleway;
  background: rgba(76, 105, 151, 0.9);
}
.fullscreen-search:target, .fullscreen-search[data-is-shown=true] {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: auto;
  height: auto;
  backdrop-filter: blur(8px) grayscale(100%);
}
.fullscreen-search form {
  font-size: 1rem;
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 10% 0 0 0;
  text-align: left;
  color: #ffffff;
  line-height: 1.4;
}
@media screen and (min-height: 35em) {
  .fullscreen-search form {
    padding-top: 14%;
  }
}
@media screen and (orientation: portrait) {
  .fullscreen-search form {
    padding-top: 33%;
  }
}
.fullscreen-search .fullscreen-search-close {
  transition: background-color 0.15s ease-out;
  position: absolute;
  top: 0;
  right: 0;
  width: 72px;
  height: 72px;
  cursor: pointer;
  background: transparent url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23fff' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E") no-repeat 50% 50%;
}
.fullscreen-search .fullscreen-search-close:hover,
.fullscreen-search .fullscreen-search-close:focus,
.fullscreen-search .fullscreen-search-close:active {
  background-color: #25426f;
}
.fullscreen-search .fullscreen-search-label {
  font-size: 1.125rem;
  display: block;
  margin: 0 0 0.9em 0;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
}
.fullscreen-search .pole {
  position: relative;
}
.fullscreen-search .fullscreen-search-button {
  appearance: none;
  border: 0;
  background: transparent;
  color: #ffffff;
  width: 2.5em;
  height: 2.5em;
  position: absolute;
  right: 0;
  top: 50%;
  margin: -1.3em 0 0 0;
}
@media screen and (min-width: 1025px) {
  .fullscreen-search .fullscreen-search-button {
    width: 5em;
    height: 5em;
    margin: -2.5em 0 0 0;
  }
}
.fullscreen-search .fullscreen-search-input {
  box-sizing: border-box;
  font-size: 2.375rem;
  width: 100%;
  font-family: Raleway;
  background: transparent none !important;
  color: #ffffff;
  outline: 0;
  font-weight: bold;
  padding: 0 1em 0 0;
  border-width: 0 0 2px 0;
  border-color: rgba(255, 255, 255, 0.8);
  border-style: dotted;
}
.fullscreen-search .fullscreen-search-input:focus {
  box-shadow: none !important;
}
.fullscreen-search .fullscreen-search-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
@media screen and (min-width: 601px) {
  .fullscreen-search .fullscreen-search-input {
    font-size: 2.625rem;
  }
}
@media screen and (min-width: 1025px) {
  .fullscreen-search .fullscreen-search-input {
    font-size: 5rem;
  }
}
@media screen and (min-width: 1601px) {
  .fullscreen-search .fullscreen-search-input {
    font-size: 5.375rem;
  }
}

.inline-form {
  padding-bottom: 3em;
}

.inline-form #skryvaci,
.inline-form-word {
  display: inline-block;
}

.inline-form-search {
  position: relative;
}
.inline-form-search input,
.inline-form-search label,
.inline-form-search textarea,
.inline-form-search select {
  font-size: 1rem;
}

.inline-form-word {
  margin: 0.25em 0;
}

.inline-form-input {
  box-sizing: border-box;
  max-width: 12em;
  padding: 2px 10px;
}

.inline-form-datepicker {
  padding-right: 30px;
  background-image: url(/aspinclude/vismoWeb5/html/images/abonent_calendar_2016.svg);
  background-size: 18px auto;
  background-repeat: no-repeat;
  background-position: 98% 48%;
  max-width: 8em;
}

.inline-form-input,
.inline-form-select {
  border-radius: 4px;
  border: 1px #888888 solid;
  height: 1.75em;
}

.inline-form .fbtn {
  margin-bottom: -3em;
}

.inline-form-select {
  max-width: 13em;
  padding-right: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-overflow: ellipsis;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='15px' height='15px' viewBox='0 0 15 15' xml:space='preserve'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23444444' d='M4,0l4,7H0L4,0z M4,15L0,8h8L4,15z'/%3E%3C/svg%3E");
  background-position: 100% 50%;
  background-repeat: no-repeat;
}

.inline-form #prepinac {
  margin-top: 2em;
}
@media screen and (min-width: 601px) {
  .inline-form #prepinac {
    position: absolute;
    left: 0;
    bottom: -2.3em;
    font-size: 1rem;
  }
}

/***********************
*	TABULKY
***********************/
.blokposun {
  background-color: #eeeeee;
}

.seznam {
  border: 0;
}
.seznam th a,
.seznam th a:link,
.seznam th a:visited,
.seznam th a:hover,
.seznam th a:focus,
.seznam th a:active {
  color: #ffffff;
}
.seznam td,
.seznam th {
  border: 1px white solid;
  border-width: 0 1px 1px 0;
}
.seznam td:first-child,
.seznam th:first-child {
  padding-left: 36px;
}
.seznam td:last-child,
.seznam th:last-child {
  border-right: 0;
}
.seznam th {
  font-size: 1.125rem;
  font-family: Raleway;
  line-height: 1.1;
  font-weight: normal;
  background-color: #25426f;
  color: #ffffff;
}
.seznam th a,
.seznam th a:link,
.seznam th a:visited {
  color: #ffffff;
}
.seznam td {
  color: black;
  border-width: 1px 1px 0 0;
}
.seznam .b1 td {
  border-color: #ffffff;
}
.seznam .b2 td, .seznam #isvs .hodnota td, #isvs .seznam .hodnota td {
  border-color: #ebebeb;
}

.b1 {
  background-color: #ebebeb;
}

.b2, #isvs .hodnota {
  background-color: #ffffff;
}

/**********************
*	FOTOGALERIE
**********************/
.nahledy li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 349px) {
  .nahledy li {
    width: 100%;
  }
}
@media screen and (min-width: 350px) and (max-width: 599px) {
  .nahledy li {
    width: 49%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 600px) and (max-width: 1023px) {
  .nahledy li {
    width: 32%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  .nahledy li {
    width: 23.5%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}
.nahledy div > a {
  background: #ffffff;
  border: 2px transparent solid;
  border-radius: 0px;
  min-height: 200px;
}
.nahledy div > a:hover,
.nahledy div > a:focus,
.nahledy div > a:active {
  background: transparent;
  border-color: #4c6997;
}

/**********************
*	PŘEHLEDY GALERIÍ
**********************/
.galerie-2016 .ui li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 399px) {
  .galerie-2016 .ui li {
    width: 100%;
  }
}
@media screen and (min-width: 400px) and (max-width: 1023px) {
  .galerie-2016 .ui li {
    width: 49%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  .galerie-2016 .ui li {
    width: 32%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

/***********************
*	ZÁLOŽKY
***********************/
.zalozky {
  font-size: 1.25rem;
  line-height: 1.1;
  font-family: Raleway;
  font-weight: normal;
}
.zalozky li {
  padding: 0;
  margin: 0 0.5em 0.5em 0;
  float: left;
  position: relative;
  display: block;
}
.zalozky li.azalozka {
  background: transparent none;
}
.zalozky a {
  display: block;
  text-decoration: none;
  margin: 0;
  position: relative;
  cursor: pointer;
  padding: 10px 36px 12px;
}
.zalozky a,
.zalozky a:link,
.zalozky a:visited {
  background-color: transparent;
  color: #4c6997;
}
.zalozky a:hover,
.zalozky a:focus,
.zalozky a:active {
  text-decoration: underline;
}
.zalozky .azalozka a,
.zalozky .azalozka a:link,
.zalozky .azalozka a:visited,
.zalozky .azalozka a:hover,
.zalozky .azalozka a:focus,
.zalozky .azalozka a:active {
  background-color: transparent;
  color: #25426f;
  text-decoration: none;
  cursor: default;
}

#zahlavi2 {
  margin: 20px 0;
}

#zalozkynadpis {
  padding: 15px 0 0 0;
}

.vych_pol {
  font-size: 1rem;
  text-align: right;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}
.vych_pol strong {
  font-weight: normal;
}

a[id*=k0] {
  height: 0;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
  color: transparent !important;
}

/****************************
*	S VYPNUTÝM JS NA TITULCE
****************************/
.bezjs {
  padding: 20px 0 15px 0;
}
.bezjs .inline li::before {
  color: #404040;
}

#keskryti {
  display: inline;
}

/****************************
*	SE ZAPNTÝM JS NA TITULCE
****************************/
#titul .zalozky {
  font-size: 1.75rem;
  line-height: 1.1;
  font-family: Raleway;
  text-transform: uppercase;
}
@media screen and (min-width: 1151px) {
  #titul .zalozky {
    font-size: 2.125rem;
  }
}
@media screen and (min-width: 1301px) {
  #titul .zalozky {
    font-size: 2.5rem;
  }
}
#titul .zalozky .inline li::before {
  content: normal;
}
#titul .zalozky a {
  padding: 15px 0;
  margin-right: 50px;
}
#titul .zalozky a,
#titul .zalozky a:link,
#titul .zalozky a:visited {
  background-color: transparent;
  color: #4c6997;
  font-weight: 300;
}
#titul .zalozky a:hover,
#titul .zalozky a:focus,
#titul .zalozky a:active {
  text-decoration: underline;
}
#titul .zalozky .nzalozka a {
  padding-right: 42px;
  position: relative;
}
#titul .zalozky .nzalozka a::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 26px;
  background-image: url(images/sipkaZalozky.png), url(images/sipkaLinkaZalozky.png);
  background-repeat: no-repeat;
  background-position: left center;
  transition: background-position 0.5s;
}
#titul .zalozky .nzalozka a:hover::after {
  background-position: right center, left center;
}
#titul .zalozky .azalozka a,
#titul .zalozky .azalozka a:link,
#titul .zalozky .azalozka a:visited,
#titul .zalozky .azalozka a:hover,
#titul .zalozky .azalozka a:focus,
#titul .zalozky .azalozka a:active {
  background-color: transparent;
  color: #25426f;
  font-weight: 700;
  text-decoration: none;
}
#titul .zalozky .azalozka a:hover,
#titul .zalozky .azalozka a:link:hover,
#titul .zalozky .azalozka a:visited:hover,
#titul .zalozky .azalozka a:hover:hover,
#titul .zalozky .azalozka a:focus:hover,
#titul .zalozky .azalozka a:active:hover {
  text-decoration: none;
}

@media screen and (max-width: 649px) {
  #linkynakotvy {
    display: none;
  }
  #titul .poz h2.cist {
    position: static;
    width: auto;
    height: auto;
    color: #25426f;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    font-family: Raleway;
  }
  #titul .poz .skryty {
    display: block;
  }
}
/******************
* MODULY
******************/
.svatekPocasiPozor {
  width: 15em;
  font-size: 1rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.svatekPocasiPozor h2 {
  color: #25426f;
}
.svatekPocasiPozor .box {
  background-color: rgba(37, 66, 111, 0.8);
  height: 158px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 7.25em;
  padding: 10px 0;
  margin: 10px 0;
  box-sizing: border-box;
}
.svatekPocasiPozor .box * {
  color: white;
  box-sizing: border-box;
}
.svatekPocasiPozor #upozorneni {
  width: 100%;
  margin: 0;
}
@media screen and (min-width: 451px) {
  .svatekPocasiPozor {
    width: 100%;
    margin: 0;
    justify-content: space-evenly;
  }
}
@media screen and (min-width: 601px) {
  .svatekPocasiPozor {
    justify-content: space-between;
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 0;
    width: 15.5em;
  }
}
@media screen and (min-width: 851px) {
  .svatekPocasiPozor {
    max-height: 356px;
  }
}
@media screen and (min-width: 1101px) {
  .svatekPocasiPozor {
    width: 7.25em;
    align-items: center;
    max-height: 100%;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
  .svatekPocasiPozor .box {
    margin: 5px 0;
    height: 156px;
  }
  .svatekPocasiPozor .box:hover {
    width: 21.75em !important;
  }
  .svatekPocasiPozor .box:hover#pocasi .owm2, .svatekPocasiPozor .box:hover#pocasi .owm3 {
    display: flex;
  }
  .svatekPocasiPozor .box:hover .jmZitra, .svatekPocasiPozor .box:hover .jmPozitri {
    display: block;
  }
}
@media screen and (min-width: 1401px) {
  .svatekPocasiPozor .box {
    margin: 10px 0;
    height: 158px;
  }
}

#obalJmeniny .box {
  display: flex;
  flex-wrap: nowrap;
}

.jmeniny {
  text-align: center;
  padding: 0 10px;
  width: 7.25em;
}
.jmeniny .datum {
  font-size: 1.875rem;
  line-height: 30px;
  font-weight: bold;
  padding-bottom: 7px;
}
.jmeniny .svatek {
  font-size: 0.9375rem;
}
.jmeniny .jmeno {
  word-wrap: break-word;
  width: 6em;
}

.jmZitra, .jmPozitri {
  display: none;
}
.jmZitra .datum, .jmPozitri .datum {
  font-size: 1.5rem;
}

#uvodtitul {
  overflow: hidden;
  margin: 0 0 30px 0;
}

#pocasi {
  font-size: 1rem;
  padding: 10px 0;
}
#pocasi ul {
  display: flex;
  flex-wrap: nowrap;
}
#pocasi li {
  flex-wrap: wrap;
  margin: 0;
  padding: 0 10px;
  width: 7.25em;
}
#pocasi li img {
  width: 64px;
}
#pocasi li span {
  display: block;
  width: 100%;
}
#pocasi .owm1 {
  display: flex;
}
#pocasi .owm2, #pocasi .owm3 {
  display: none;
}
#pocasi .owmIcon {
  order: 1;
  padding: 0;
}
#pocasi .owmDateDay {
  order: 2;
}
#pocasi .owmTempMax {
  order: 3;
}
#pocasi .zdroj {
  display: block;
  text-align: center;
  width: 100%;
}
@media screen and (min-width: 601px) {
  #pocasi .zdroj {
    text-align: left;
    padding-left: 38px;
  }
}
#pocasi .owmDateNumber, #pocasi .owmDateDayName, #pocasi .owmTempMin {
  display: none !important;
}

#upozorneni {
  font-size: 1rem;
  color: white;
  background: #4c6997;
  padding: 16px 3%;
  box-sizing: border-box;
  max-width: 1920px;
  margin: 0 auto;
}
#upozorneni a {
  color: white;
  text-decoration: none;
}
#upozorneni a:hover {
  text-decoration: underline;
}
#upozorneni a, #upozorneni span {
  position: relative;
  line-height: 1.5;
}
#upozorneni a > span, #upozorneni .vicedok {
  top: 0;
}
#upozorneni .pozor {
  font-weight: 700;
}
#upozorneni .pozor::before {
  content: normal;
  display: inline-block;
  width: 41px;
  height: 36px;
  margin: 0 20px 0 0;
  background: url(images/pozor.png) no-repeat 0 0;
  position: relative;
  top: 10px;
}
@media screen and (min-width: 601px) {
  #upozorneni {
    padding: 5px 3% 2px;
  }
  #upozorneni .pozor::before {
    content: "";
  }
  #upozorneni a, #upozorneni span {
    top: -10px;
  }
}
#upozorneni .vicedok {
  text-decoration: underline;
}
#upozorneni .vicedok:hover {
  text-decoration: none;
}

#temata {
  padding: 20px 0 38px;
}
@media screen and (min-width: 601px) {
  #temata {
    padding: 30px 0 48px;
  }
}
#temata * {
  box-sizing: border-box;
}
#temata ul {
  display: flex;
  flex-wrap: wrap;
}
#temata .ui li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #f7f7f7;
  position: relative;
}
@media screen and (max-width: 499px) {
  #temata .ui li {
    width: 100%;
  }
}
@media screen and (min-width: 500px) and (max-width: 799px) {
  #temata .ui li {
    width: 49.15%;
    margin-left: 1.7%;
  }
  #temata .ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 800px) and (max-width: 1099px) {
  #temata .ui li {
    width: 32.2%;
    margin-left: 1.7%;
  }
  #temata .ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1100px) {
  #temata .ui li {
    width: 23.725%;
    margin-left: 1.7%;
  }
  #temata .ui li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 500px) {
  #temata .ui li {
    min-height: 144px;
  }
}
@media screen and (max-width: 499px) {
  #temata .ui li:nth-child(2n+2) {
    background-color: #ededee;
  }
}
@media screen and (min-width: 500px) and (max-width: 799px) {
  #temata .ui li:nth-child(4n+2), #temata .ui li:nth-child(4n+3) {
    background-color: #ededee;
  }
}
@media screen and (min-width: 800px) and (max-width: 1099px) {
  #temata .ui li:nth-child(6n+2), #temata .ui li:nth-child(6n+4), #temata .ui li:nth-child(6n+6) {
    background-color: #ededee;
  }
}
@media screen and (min-width: 1100px) {
  #temata .ui li:nth-child(8n+2), #temata .ui li:nth-child(8n+4), #temata .ui li:nth-child(8n+5), #temata .ui li:nth-child(8n+7) {
    background-color: #ededee;
  }
}
#temata .ui li:hover {
  background-color: #e8eaee;
}
#temata .ui li:hover strong a {
  background-color: #25426f;
}
#temata .ui li:hover div {
  color: #25426f;
}
#temata .ui li:hover .sipkaTemata span::after {
  content: "";
  position: absolute;
  right: 17px;
  bottom: -22px;
  width: 40px;
  height: 23px;
  background: url(images/sipkaTemata.png) no-repeat 0 0;
  display: block;
}
#temata .ui li > strong a {
  display: block;
  padding: 16px 16px 14px 18px;
  background-color: #4c6997;
  color: white;
  font-size: 1.125rem;
  font-family: Raleway;
  font-weight: bold;
}
@media screen and (min-width: 1201px) {
  #temata .ui li > strong a {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 1401px) {
  #temata .ui li > strong a {
    font-size: 1.625rem;
  }
}
#temata .ui li > strong a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}
#temata .ui li > strong.sipkaTemata span {
  display: block;
  position: relative;
}
#temata .ui li div {
  font-size: 1rem;
  font-family: "Open sans";
  color: #4c6997;
  font-weight: 300;
  padding: 28px 16px 28px 18px;
}
@media screen and (min-width: 1201px) {
  #temata .ui li div {
    font-size: 1.125rem;
  }
}

#kalendar {
  background-color: #4c6997;
  padding: 40px 0;
}
#kalendar .nadpis {
  color: white;
  padding-bottom: 30px;
}

#vypisakci .seznamAkci {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
@media screen and (min-width: 501px) {
  #vypisakci .seznamAkci {
    min-height: 462px;
  }
}
#vypisakci li {
  margin: 0;
  padding: 0;
}
#vypisakci .nazevAkce {
  font-size: 0.875rem;
  font-family: "Open sans";
  font-weight: 300;
  padding: 6px 12px;
  background-color: #ffffff;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;
}
@media screen and (min-width: 501px) {
  #vypisakci .nazevAkce {
    padding: 12px 20px;
    font-size: 1rem;
    min-height: 77px;
  }
}
@media screen and (min-width: 1251px) {
  #vypisakci .nazevAkce {
    font-size: 1.125rem;
  }
}
#vypisakci .nazevAkce a {
  color: #000000;
}
#vypisakci .nazevAkce a::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media screen and (min-width: 801px) {
  #vypisakci .nazevAkce {
    width: 50%;
    margin-left: 50%;
  }
  #vypisakci .nazevAkce.selectedSection, #vypisakci .nazevAkce.selectedSection a, #vypisakci .nazevAkce.selectedSection .datumAkce {
    color: white;
    background-color: #25426f;
  }
}
#vypisakci .datumAkce {
  font-size: 1rem;
  color: #25426f;
  font-family: Raleway;
  font-weight: bold;
  float: left;
  width: 6.7em;
  padding: 10px 0;
}
@media screen and (min-width: 501px) {
  #vypisakci .datumAkce {
    font-size: 1.125rem;
    padding: 10px 0 10px 3%;
  }
}
@media screen and (min-width: 1101px) {
  #vypisakci .datumAkce {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 1401px) {
  #vypisakci .datumAkce {
    font-size: 1.625rem;
  }
}
#vypisakci .datumAkce .datum-cislo:not(:empty)::after {
  content: ".";
}
@media screen and (max-width: 800px) {
  #vypisakci .obsahAkce {
    display: none !important;
  }
}
#vypisakci .obsahAkce {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  background-color: #25426f;
  box-sizing: border-box;
  padding: 46px 20px 46px 3%;
}
#vypisakci .obsahAkce, #vypisakci .obsahAkce a {
  color: white;
}
#vypisakci .upoutavka {
  float: left;
  width: 47%;
  max-width: 360px;
}
#vypisakci .upoutavka img {
  max-width: 100%;
  max-height: 365px;
  height: auto;
  width: auto;
}
#vypisakci .podrobnosti {
  float: right;
  width: 43.5%;
  font-size: 1.125rem;
  font-family: "Open sans";
  font-weight: 300;
  line-height: 1.4;
}
#vypisakci .misto {
  display: none;
}
#vypisakci .popis:not(:empty) {
  margin-bottom: 32px;
}
#vypisakci .vice {
  padding-right: 12px;
  background: url(images/sipkaAkce.png) no-repeat right center;
  color: #ffffff;
  font-family: Raleway;
  font-size: 1.25rem;
  position: absolute;
  right: 24px;
  bottom: 24px;
}
#vypisakci .progressBar {
  display: none;
}
@media screen and (min-width: 801px) {
  #vypisakci .progressBar {
    position: relative;
    left: 3%;
    width: calc(46% - 80px);
    height: 2px;
    top: -31px;
    background-color: #4c6997;
    display: block;
  }
  #vypisakci .progressBar span {
    display: block;
    height: 2px;
    background-color: white;
  }
}

#filtr {
  padding: 26px 0;
  overflow: hidden;
}
#filtr ul, #filtr li {
  margin: 0;
  list-style: none;
  padding: 0;
  float: left;
}
#filtr li {
  padding: 1em 1em 0 0;
  max-width: 14.7em;
  min-width: 10em;
  font-size: 1rem;
  width: 33.3%;
  box-sizing: border-box;
}
#filtr ul {
  width: calc(100% + 1em);
  max-width: 794px;
  font-size: 1rem;
}
@media screen and (min-width: 601px) {
  #filtr ul {
    width: calc(100% + 1.4em);
    font-size: 1.125rem;
  }
  #filtr ul li {
    font-size: 1.125rem;
    padding: 1em 1.4em 0 0;
  }
}
@media screen and (min-width: 801px) {
  #filtr ul {
    width: calc(100% - 180px);
  }
}
#filtr a {
  border: 1px solid white;
  color: white;
  font-family: "Open sans";
  font-weight: 300;
  display: block;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
}
#filtr a:hover {
  text-decoration: underline;
}
#filtr .vsechny {
  border-color: #25426f;
  background-color: #25426f;
  float: left;
  margin: 16px 0;
  font-size: 1rem;
  width: 16%;
  max-width: 13.3em;
  min-width: 10em;
  color: white;
}
@media screen and (min-width: 601px) {
  #filtr .vsechny {
    font-size: 1.125rem;
  }
}

#kalendarAkci .editor {
  margin-top: 40px;
  margin-bottom: 40px;
}

.rezervace {
  background-color: #f6f6f6;
  padding: 40px 0;
}
@media screen and (min-width: 601px) {
  .rezervace {
    padding: 0;
  }
  .rezervace .centrovany {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 248px;
    position: relative;
  }
}
.rezervace .textRezervace {
  box-sizing: border-box;
}
@media screen and (min-width: 601px) {
  .rezervace .textRezervace {
    padding: 0 6% 0 0;
    width: calc(100% - 140px);
  }
}
@media screen and (min-width: 801px) {
  .rezervace .textRezervace {
    padding: 0 6% 0 41.7%;
    width: 87.7%;
  }
  .rezervace .textRezervace::before {
    content: "";
    background: url(images/rezervace.jpg) no-repeat left center;
    width: 35.7%;
    height: 152px;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.rezervace .textRezervace p {
  color: #000000;
  font-size: 1.125rem;
  font-family: "Open sans";
  font-weight: 300;
  margin: 0;
}
.rezervace .textRezervace .podnadpis {
  font-weight: bold;
  font-size: 1.625rem;
  color: #25426f;
  margin: 0;
  padding-bottom: 12px;
}
.rezervace a {
  font-family: "Open sans";
  font-weight: 300;
  display: block;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  background-color: #25426f;
  width: 140px;
  color: white;
  font-size: 1.125rem;
  margin-top: 12px;
  text-decoration: none;
}
.rezervace a:hover {
  text-decoration: underline;
}
@media screen and (min-width: 601px) {
  .rezervace a {
    width: 125px;
    margin: 0;
  }
}
@media screen and (min-width: 801px) {
  .rezervace a {
    width: 17%;
  }
}

.volnycas {
  padding: 40px 0 55px;
}
.volnycas ul, .volnycas li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.volnycas li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  position: relative;
  padding-bottom: 53.5%;
  max-width: 495px;
  margin: 20px auto 0;
  float: none;
}
@media screen and (max-width: 600px) {
  .volnycas li {
    width: 100%;
  }
}
@media screen and (min-width: 601px) and (max-width: 1000px) {
  .volnycas li {
    width: 49.1%;
    margin-left: 1.8%;
  }
  .volnycas li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1001px) {
  .volnycas li {
    width: 32.1333333333%;
    margin-left: 1.8%;
  }
  .volnycas li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 601px) {
  .volnycas li {
    padding-bottom: 29%;
    float: left;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1001px) {
  .volnycas li {
    padding-bottom: 19%;
  }
}
.volnycas li a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
}
.volnycas li a::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  top: 100%;
  transition: top 0.75s;
  background: rgba(37, 66, 111, 0.8);
}
.volnycas li a:hover::before {
  top: 0;
}
.volnycas li a:hover span {
  background: transparent;
}
.volnycas li a span {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.6em;
  line-height: 1em;
  background: rgba(37, 66, 111, 0.8);
  color: white;
  font-family: Raleway;
  font-size: 1.125rem;
  font-weight: bold;
}
@media screen and (min-width: 801px) {
  .volnycas li a span {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 1501px) {
  .volnycas li a span {
    font-size: 1.625rem;
  }
}

.aboMesta {
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
}
.aboMesta h2 {
  color: #25426f;
}
.aboMesta .centrovany > div {
  position: relative;
  box-sizing: border-box;
}
.aboMesta .centrovany > div::before, .aboMesta .centrovany > div::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 190px;
}
.aboMesta .centrovany #abo {
  background-color: #f6f6f6;
}
.aboMesta .centrovany #abo::before {
  right: 100%;
  background-color: #f6f6f6;
}
.aboMesta .centrovany #abo::after {
  left: 100%;
  background-color: #f6f6f6;
}
.aboMesta .centrovany .mesta {
  background-color: #ededee;
  padding: 40px 0 40px 4%;
}
.aboMesta .centrovany .mesta::before {
  left: 100%;
  background-color: #ededee;
}
.aboMesta .centrovany .mesta::after {
  right: 100%;
  background-color: #ededee;
}
.aboMesta .centrovany .mesta a {
  color: #25426f;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 300;
  font-family: "Open sans";
  text-decoration: none;
}
.aboMesta .centrovany .mesta a img {
  display: inline-block;
  margin: 5px 0 25px;
}
.aboMesta .centrovany .mesta a:hover {
  text-decoration: underline;
}
@media screen and (min-width: 801px) {
  .aboMesta .centrovany {
    display: flex;
    flex-wrap: nowrap;
  }
  .aboMesta .centrovany > div::after {
    content: normal;
  }
  .aboMesta .centrovany #abo {
    width: 66.3%;
  }
  .aboMesta .centrovany .mesta {
    width: 33.7%;
  }
}

#obalmbannery {
  min-width: 320px;
  background: #ffffff;
}

#mbannery {
  padding: 45px 0;
  color: #000000;
  margin: 0;
}
#mbannery .editor {
  text-align: center;
}
#mbannery .editor a,
#mbannery .editor a:link,
#mbannery .editor a:visited,
#mbannery .editor a:hover,
#mbannery .editor a:focus,
#mbannery .editor a:active {
  color: #000000 !important;
}
#mbannery li {
  margin-top: 0;
  margin-bottom: 0;
}

#kontakt address {
  display: block;
  font-style: normal;
}

#anketa p {
  padding: 0;
  margin: 0 0 1em 0;
}
#anketa ul {
  list-style-type: none;
  padding: 0;
  margin: 8px 0 5px 0;
}
#anketa ul li {
  margin-bottom: 8px;
}
#anketa .hlas {
  margin-top: 3px;
  z-index: 1;
}
#anketa .hlas div {
  height: 12px;
  background-color: #228be6;
}

#pocitadlo {
  margin: 15px 0 0 0;
  text-align: center;
  padding-bottom: 15px;
}
#pocitadlo ul.inline {
  padding-left: 0;
  display: inline;
}
#pocitadlo ul.inline li span {
  margin: 0 0 0 2px;
}
@media screen and (max-width: 599px) {
  #pocitadlo li {
    display: block !important;
  }
  #pocitadlo li + li::before {
    content: "";
    margin: 0;
  }
}

/*******************************
* KALENDÁŘE
*******************************/
.mesice {
  display: flex;
  justify-content: space-between;
  color: #2a2a2a;
}
#stranka .mesice .c-actual-month {
  text-align: center;
}

#kalakci {
  max-width: 30em;
  width: 104%;
  margin-left: -2%;
}
@media screen and (min-width: 451px) {
  #kalakci {
    width: 106%;
    margin-left: -3%;
  }
}
#kalakci .calcells {
  width: 100%;
  border-spacing: 0 1.5em;
  font-size: 0.875rem;
}
#kalakci .calcells td,
#kalakci .calcells th {
  box-sizing: border-box;
  position: relative;
}
#kalakci .calcells th {
  top: -6px;
  color: #2a2a2a;
}
#kalakci .calcells td {
  width: 14.28%;
  height: 2em;
  text-align: center;
  font-size: 0.875rem;
  z-index: 0;
}
#kalakci .calcells td::before {
  content: "";
  position: absolute;
  display: block;
  width: 2em;
  height: 2em;
  border-radius: 500em;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -1em 0 0 -1em;
  box-sizing: border-box;
  z-index: -1;
}
#kalakci .calcells td.wkday::before {
  background-color: transparent;
  border: 2px blue solid;
}
#kalakci .calcells td.wkday, #kalakci .calcells td.wkday a {
  color: #2a2a2a;
  text-decoration: none;
}
#kalakci .calcells td.curdate::before {
  background-color: magenta;
}
#kalakci .calcells td.curdate, #kalakci .calcells td.curdate a {
  color: #ffffff;
}
#kalakci .calcells td.curdate:hover, #kalakci .calcells td.curdate a:hover {
  color: #ffffff !important;
}
#kalakci .calcells td.notmnth::before {
  background-color: transparent;
  border: 1px blue solid;
}
#kalakci .calcells td.notmnth, #kalakci .calcells td.notmnth a {
  color: #2a2a2a;
  text-decoration: none;
}
#kalakci .calcells td.notmnth a > span {
  background-color: yellow;
  border: 0px transparent solid;
  color: #2a2a2a;
}
#kalakci .calcells td a {
  line-height: 2em;
  position: absolute;
  width: 2em;
  left: 50%;
  margin-left: -1em;
  top: 0;
  bottom: 0;
  font-size: 0.875rem;
}
#kalakci .calcells td a:hover {
  background-color: transparent !important;
  color: #2a2a2a !important;
  text-decoration: underline;
  border-radius: 500em;
}
#kalakci .calcells td a > span {
  font-weight: normal;
  font-size: 0.75rem;
  position: absolute;
  top: -0.9em;
  right: -0.4em;
  width: 1.6em;
  height: 1.6em;
  line-height: 1.7em;
  background-color: yellow;
  border: 0px transparent solid;
  color: #2a2a2a;
  border-radius: 500em;
}
@media screen and (min-width: 451px) {
  #kalakci .calcells td {
    height: 2.5em;
  }
  #kalakci .calcells td::before {
    width: 2.5em;
    height: 2.5em;
    margin: -1.25em 0 0 -1.25em;
  }
  #kalakci .calcells td a {
    line-height: 2.5em;
    width: 2.5em;
    margin-left: -1.25em;
  }
  #kalakci .calcells td a > span {
    right: -0.7em;
  }
}
@media screen and (min-width: 601px) {
  #kalakci .calcells {
    font-size: 1rem;
  }
  #kalakci .calcells td, #kalakci .calcells td a {
    font-size: 1rem;
  }
  #kalakci .calcells td a > span {
    font-size: 0.875rem;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #kalakci .calcells td {
    line-height: 2em;
  }
  #kalakci .calcells td::before {
    margin-top: 0;
    top: 0;
  }
  #kalakci .calcells td a {
    bottom: auto;
  }
}

.akce-podle-data {
  clear: both;
}

#stranka #kalakci {
  margin-top: 10px;
  margin-bottom: 25px;
  max-width: 41.25em;
  float: left;
}
#stranka #kalakci .c-arrow {
  display: inline-block;
}
#stranka #kalakci .c-arrow:hover {
  text-decoration: underline;
}
#stranka #kalakci .c-before-month, #stranka #kalakci .c-next-month {
  padding: 0;
}
#stranka #kalakci .c-before-month .space, #stranka #kalakci .c-before-month .c-moth, #stranka #kalakci .c-before-month .c-year, #stranka #kalakci .c-next-month .space, #stranka #kalakci .c-next-month .c-moth, #stranka #kalakci .c-next-month .c-year {
  display: none;
}
#stranka #kalakci .c-actual-month {
  padding-left: 0;
  text-align: center;
}
#stranka #kalakci .mesice {
  margin: 0 auto;
  display: table;
  width: 100%;
  line-height: 0.8;
  table-layout: fixed;
  border-spacing: 1px 0;
  margin-bottom: 10px;
  font-size: 1.25rem;
}
#stranka #kalakci .mesice .c-actual-month {
  display: table-cell;
  width: 71%;
  vertical-align: bottom;
}
#stranka #kalakci .mesice + p {
  margin: 20px 0;
}
#stranka #kalakci .mesice a {
  display: table-cell;
  vertical-align: bottom;
  text-decoration: underline;
  color: #232323;
  text-align: center;
}
#stranka #kalakci .mesice a:hover {
  text-decoration: none !important;
}
#stranka #kalakci .calcells td.wkday::before {
  background-color: transparent;
  border: 2px #25426f solid;
}
#stranka #kalakci .calcells td.wkday, #stranka #kalakci .calcells td.wkday a {
  color: #2a2a2a;
}
#stranka #kalakci .calcells td.curdate::before {
  background-color: #25426f;
}
#stranka #kalakci .calcells td.curdate, #stranka #kalakci .calcells td.curdate a {
  color: #ffffff;
}
#stranka #kalakci .calcells td.curdate:hover, #stranka #kalakci .calcells td.curdate a:hover {
  color: #ffffff !important;
}
#stranka #kalakci .calcells td.notmnth::before {
  background-color: transparent;
  border: 1px #25426f solid;
}
#stranka #kalakci .calcells td.notmnth, #stranka #kalakci .calcells td.notmnth a {
  color: #555555;
}
#stranka #kalakci .calcells td.notmnth a > span {
  background-color: #4c6997;
  border: 0px gold solid;
  color: #ffffff;
}
#stranka #kalakci .calcells td a:hover {
  background-color: transparent !important;
  color: #2a2a2a !important;
}
#stranka #kalakci .calcells td a > span {
  background-color: #4c6997;
  border: 0px gold solid;
  color: #ffffff;
}

/*****************************************
* MIXINY A PROMĚNNÉ PRO TABULKOVÉ MENU
*****************************************/
/****************************************/
/******************************
* HORIZONTÁLNÍ MENU A VÝBĚRY
******************************/
#obalmenu1 {
  position: relative;
  z-index: 15;
  overflow: hidden;
}
@media screen and (min-width: 601px) and (max-width: 800px) {
  #obalmenu1 {
    border-top: 72px solid #25426F;
  }
}

#menu1 {
  box-sizing: border-box;
}
#menu1 ul {
  text-align: center;
  padding: 10px 0 8px;
}
#menu1 .akt a {
  font-weight: bold;
  letter-spacing: -0.025em;
}
#menu1 .akt a::after {
  content: "";
}
#menu1 .akt a:hover {
  text-decoration: none;
}
#menu1 li {
  line-height: 20px;
  font-size: 1rem;
  font-family: Raleway;
  padding: 11px 0.6em 0;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}
@media screen and (min-width: 1361px) {
  #menu1 li {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 1501px) {
  #menu1 li {
    font-size: 1.25rem;
  }
}
#menu1 li a,
#menu1 li a:link,
#menu1 li a:visited {
  text-decoration: none;
  color: #ffffff;
}
#menu1 li a:hover,
#menu1 li a:focus,
#menu1 li a:active {
  text-decoration: underline;
}
#menu1 li #osmakth,
#menu1 li #osmakth:link,
#menu1 li #osmakth:visited,
#menu1 li #osmakth:hover,
#menu1 li #osmakth:focus,
#menu1 li #osmakth:active {
  cursor: text;
  text-decoration: none;
}
@media screen and (min-width: 601px) {
  #menu1 ul {
    padding: 8px 0;
  }
}
@media screen and (min-width: 801px) {
  #menu1 {
    padding: 0 0 0 300px;
  }
  #menu1 ul {
    padding: 0;
    height: 76px;
    text-align: left;
  }
}
@media screen and (min-width: 936px) {
  #menu1 ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + 0.5em);
    font-size: 1.25rem;
    margin-right: -0.5em;
  }
  #menu1 li {
    padding: 12px 0.5em;
  }
  #menu1 li a::after {
    content: normal;
    position: absolute;
    bottom: -16px;
    display: block;
    width: 31px;
    height: 15px;
    margin-left: -15px;
    left: 50%;
    background: url(images/sipkaMenu.png) no-repeat 0 0;
  }
  #menu1 li a:hover::after {
    content: "";
  }
}
@media screen and (min-width: 1251px) {
  #menu1 {
    padding-left: 500px;
  }
}
@media screen and (min-width: 1351px) {
  #menu1 {
    padding-left: 520px;
  }
}

@media screen and (min-width: 901px) {
  .fixed-menu #obalhlava {
    height: 53px;
    position: fixed;
    width: 100%;
    max-width: 1920px;
    box-sizing: border-box;
    text-align: left;
    margin-top: 53px;
    top: -53px;
    padding: 8px 0;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
    transition: margin 0.75s;
    border-bottom: 1px solid #ffffff;
    background-color: #4c6997;
  }
  .fixed-menu #hlava {
    max-width: 1540px;
    margin: 0 auto;
  }
  .fixed-menu #lista {
    display: none;
  }
  .fixed-menu .obalNazev {
    background: transparent;
  }
  .fixed-menu .obalNazev .centrovany {
    width: 100%;
  }
  .fixed-menu .obalNazev #nazev {
    background: transparent;
    padding: 0;
    width: auto;
  }
  .fixed-menu .obalNazev #nazev::before {
    content: normal;
  }
  .fixed-menu .obalNazev img, .fixed-menu .obalNazev h1.cvi span {
    display: none;
  }
  .fixed-menu .obalNazev h1.cvi {
    margin: 0 0 0 22px;
    padding: 0;
  }
  .fixed-menu .obalNazev h1.cvi strong {
    font-size: 2rem;
    display: block;
    color: white;
  }
  .fixed-menu #obalmenu1 #menu1 {
    padding-left: 250px;
  }
  .fixed-menu #obalmenu1 ul {
    height: 37px;
    max-width: 1020px;
    float: right;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + 0.5em);
    margin-right: -0.5em;
  }
  .fixed-menu #obalmenu1 li {
    padding: 0;
  }
  .fixed-menu #obalmenu1 li a:hover::after, .fixed-menu #obalmenu1 li.akt a::after {
    content: normal;
  }
}
/*************************
* VÝBĚRY MENU
*************************/
#stranka.noleftpanel #telo {
  max-width: 100%;
}
#stranka.noleftpanel #telo #stred {
  width: 100%;
  float: none;
  clear: both;
  padding: 14px 0 0 !important;
}
#stranka.noleftpanel #telo #stred #zahlavi h2.cvi {
  margin: 0 0 26px;
}
#stranka.noleftpanel #menu-start {
  display: none;
}
#stranka.noleftpanel .mapa-stranek-2016 {
  clear: both;
}
#stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici {
  position: relative;
  margin-bottom: 44px;
}
#stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 500px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 100%;
  }
}
@media screen and (min-width: 501px) and (max-width: 799px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 49.25%;
    margin-left: 1.5%;
  }
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 800px) and (max-width: 1099px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 32.3333333333%;
    margin-left: 1.5%;
  }
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1100px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 23.875%;
    margin-left: 1.5%;
  }
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}
#stranka.noleftpanel .dok {
  max-width: 800px;
}

#osnova * {
  font-style: normal;
}

.mapa-stranek-2016 h3.souvisejiciodkazy {
  display: none;
}
.mapa-stranek-2016 .editor {
  margin-top: 30px;
  max-width: 100%;
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  position: relative;
  min-height: 120px;
}
@media screen and (max-width: 500px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 100%;
  }
}
@media screen and (min-width: 501px) and (max-width: 1099px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 48.75%;
    margin-left: 2.5%;
  }
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1100px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 31.6666666667%;
    margin-left: 2.5%;
  }
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui li:hover {
  background: #e2e5ea;
}
@media screen and (min-width: 501px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    margin-top: 14px;
    margin-bottom: 14px;
  }
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui li a {
  border-top: 19px solid #4c6997;
  color: #25426f;
  padding: 20px 5%;
  display: block;
  text-decoration: none;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.125rem;
  font-weight: normal;
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui li a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui li a:hover {
  border-top-color: #25426f;
  text-decoration: underline;
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui li a:hover::after {
  content: "";
  position: absolute;
  right: 5%;
  top: 16px;
  width: 40px;
  height: 23px;
  background: url(images/sipkaTemata.png) no-repeat 0 0;
  display: block;
}
@media screen and (min-width: 1201px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li a {
    font-size: 1.25rem;
    padding: 20px 8%;
  }
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li a:hover::after {
    right: 8%;
  }
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui li div {
  color: #444444;
  font-size: 1rem;
  padding: 0 5% 12px;
}
@media screen and (min-width: 1201px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li div {
    padding: 0px 8% 16px;
  }
}
.mapa-stranek-2016 .pataprvku, .mapa-stranek-2016 .popis {
  margin: 30px 0;
  box-sizing: border-box;
  font-size: 16px;
  max-width: 722px;
  width: 100%;
}
.mapa-stranek-2016 .pataprvku {
  padding-top: 30px;
  border-top: 1px solid #dfdfdf;
}

/***********************
*** ROZBALOVACÍ MENU ***
***********************/
.menu-controls,
#menu-start {
  display: none;
}

.nadmenu,
#nadmenu {
  display: none;
}

/**********************
* MOBILNÍ MENU
**********************/
#menu {
  background-color: #25426f;
  padding: 20px;
}
#menu > ul a {
  padding: 5px 20px;
  margin-left: -20px;
  display: block;
}
#menu > ul a,
#menu > ul a:link,
#menu > ul a:visited {
  color: #ffffff;
}
#menu > ul #osmakt,
#menu > ul #osmakt:link,
#menu > ul #osmakt:visited,
#menu > ul #osmakt:hover,
#menu > ul #osmakt:focus,
#menu > ul #osmakt:active {
  background-color: #4c6997;
  color: #ffffff;
  text-decoration: none;
}
#menu ul {
  padding-left: 20px;
}

@media screen and (max-width: 800px) {
  #menu-start {
    display: block;
    clear: both;
    text-align: center;
  }
  #menu-start a {
    background: #4c6997;
    display: block;
    padding: 15px 0;
  }
  #menu-start a::before {
    display: inline-block;
    vertical-align: middle;
    margin: -0.15em 0.75em 0 0;
    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='22' viewBox='0 0 30 22'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' clip-rule='evenodd' d='M29 22h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1z'/%3E%3C/svg%3E");
  }
  #menu-start a,
  #menu-start a:link,
  #menu-start a:visited {
    color: #ffffff;
    text-decoration: none;
  }
  .no-scroll {
    height: 100%;
    touch-action: none;
    overflow: hidden;
    pointer-events: none;
    -webkit-overflow-scrolling: auto;
  }
  .no-scroll body {
    height: 100%;
    touch-action: none;
    overflow: hidden;
  }
  #menu.menu-initialised {
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 200;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-width: 400px;
    height: 100vh;
    padding: 70px 20px 20px 0;
    transition: transform 0.3s ease-out;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
    -webkit-transition: -webkit-transform 0.3s ease-out;
  }
  [data-env=Safari] #menu.menu-initialised {
    max-width: 100%;
  }
  #menu.menu-initialised.menu-active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  #menu.menu-initialised > ul {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 0 0 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 101;
  }
  #menu.menu-initialised > ul > li {
    margin-bottom: 15px;
  }
  #menu.menu-initialised > ul > li:last-child {
    margin-bottom: 80px;
  }
  #menu.menu-initialised ul {
    list-style-type: none;
  }
  #menu.menu-initialised .menu-controls {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    display: block;
    height: 50px;
    z-index: 100;
    background-color: #4c6997;
    color: #ffffff;
  }
  #menu.menu-initialised .menu-controls .menu-control-back,
  #menu.menu-initialised .menu-controls .menu-control-title {
    display: block;
  }
  #menu.menu-initialised .menu-controls .menu-control-back {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 0;
    top: 0;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' d='M13.569 10.994l8.162 8.165c.355.358.355.936 0 1.294l-1.28 1.279c-.357.358-.935.358-1.293 0l-8.16-8.166-8.162 8.166c-.357.358-.935.358-1.292 0l-1.278-1.279c-.357-.358-.357-.936 0-1.294l8.162-8.165-8.152-8.154c-.357-.357-.357-.935 0-1.292l1.279-1.28c.356-.357.935-.357 1.292 0l8.151 8.154 8.152-8.154c.357-.357.935-.357 1.293 0l1.276 1.28c.359.357.359.935 0 1.292l-8.15 8.154z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 22px 22px;
  }
  #menu.menu-initialised .menu-controls .menu-control-title {
    padding: 0 20px;
    line-height: 2.2;
    border-right: 70px transparent solid;
    white-space: nowrap;
    font-size: 1.25rem;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #menu.menu-initialised .akt a {
    font-weight: bold;
    text-decoration: none;
  }
  #menu.menu-initialised .akt a:hover {
    text-decoration: none;
  }
  #menu.menu-initialised .menu-indicator {
    display: none;
  }
}
/***********************
* DESKTOPOVÉ MENU
***********************/
@media screen and (min-width: 801px) {
  .nadmenu {
    display: block;
  }
  .nadmenu a {
    position: relative;
    color: #4c6997;
    font-size: 1.1875rem;
    padding: 16px 16px 16px 60px;
    display: block;
    text-decoration: none;
    line-height: 1.15;
    background-color: #ffffff;
  }
  .nadmenu a::before {
    content: "";
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -17px;
    display: inline-block;
    width: 15px;
    height: 34px;
    background: url(images/sipkaZpet.png) no-repeat 0 0;
  }
}
@media screen and (min-width: 801px) and (min-width: 1101px) {
  .nadmenu a {
    font-size: 1.375rem;
    padding: 20px 16px 20px 80px;
  }
  .nadmenu a::before {
    left: 30px;
  }
}
@media screen and (min-width: 801px) and (min-width: 1301px) {
  .nadmenu a {
    font-size: 1.5625rem;
    padding: 24px 16px 24px 100px;
  }
  .nadmenu a::before {
    left: 40px;
  }
}
@media screen and (min-width: 801px) {
  .nadmenu a:hover {
    text-decoration: underline;
  }
  #nadmenu {
    font-size: 0.875rem;
    display: none;
    font-weight: normal;
    padding: 0;
    white-space: nowrap;
  }
  #menu {
    background-color: #25426f;
    padding: 0;
  }
  #menu h2.cvi {
    background-color: #228be6;
    padding: 10px 25px 10px 40px;
    font-size: 1.75rem;
    color: #000000;
    font-weight: normal;
  }
  #menu .menu-item-collapsed > ul {
    display: none;
  }
  #menu .menu-item-expanded > a .menu-indicator {
    transform: rotate(225deg);
  }
  #menu .menu-item-expanded > ul {
    display: block;
  }
  #menu .menu {
    font-size: 1rem;
    font-family: Raleway;
    line-height: 1.2;
    padding: 0;
  }
  #menu .menu a {
    padding: 13px 16px 12px 20px;
    display: block;
    position: relative;
    z-index: 10;
    margin-left: 0;
    text-decoration: none;
  }
}
@media screen and (min-width: 801px) and (min-width: 1101px) {
  #menu .menu {
    font-size: 1.125rem;
  }
  #menu .menu a {
    padding: 15px 16px 14px 30px;
  }
}
@media screen and (min-width: 801px) and (min-width: 1301px) {
  #menu .menu {
    font-size: 1.25rem;
  }
  #menu .menu a {
    padding: 17px 16px 16px 40px;
  }
}
@media screen and (min-width: 801px) {
  #menu .menu ul {
    text-transform: none;
  }
  #menu .menu a,
  #menu .menu a:link,
  #menu .menu a:visited {
    color: #ffffff;
    text-decoration: none;
  }
  #menu .menu a:hover,
  #menu .menu a:link:hover,
  #menu .menu a:visited:hover {
    text-decoration: underline;
  }
  #menu .menu .akt a {
    background-color: #4c6997;
  }
  #menu .menu .akt a:hover {
    text-decoration: none;
  }
  #menu .menu > li {
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
  }
  #menu .menu #osmakt,
  #menu .menu #osmakt:link,
  #menu .menu #osmakt:visited,
  #menu .menu #osmakt:hover,
  #menu .menu #osmakt:focus,
  #menu .menu #osmakt:active {
    font-weight: bold;
    background-color: transparent;
    text-decoration: none;
    cursor: default;
    border: 0;
  }
  #menu .menu .menu-indicator {
    width: 16px;
    height: 16px;
    overflow: hidden;
    position: absolute;
    top: 0.85em;
    left: -27px;
    z-index: 10;
    -webkit-transition: -webkit-transform 0.25s;
    transition: -ms-transform 0.25s;
    transition: transform 0.25s;
  }
  #menu .menu .menu-indicator img {
    margin: 0;
    cursor: pointer;
    font-size: 0.875rem;
  }
  #menu .menu ul {
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0 0 0 -40px;
    padding: 2px 0 12px 0;
  }
  #menu .menu ul a {
    padding: 1px 15px 0 40px;
    line-height: 1.5;
  }
  #menu .menu ul .menu-indicator {
    left: 13px;
    top: 0.23em;
  }
  #menu .menu ul ul {
    margin: 0;
    padding: 0 0 0 24px;
    position: static;
  }
  #menu li {
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
  }
  #menu li li {
    border: 0;
    font-weight: normal;
  }
}
#google_translate_element {
  display: inline-block;
  position: relative;
  top: -1px;
  z-index: 10;
  visibility: visible !important;
  border-radius: 3px;
  margin: 0 30px 0 15px;
}
#google_translate_element.pole {
  background-color: transparent;
}
#google_translate_element .goog-te-gadget img {
  vertical-align: middle;
  border-radius: 3px;
}
#google_translate_element .goog-te-gadget-simple {
  border: 0;
  width: 100%;
  padding-bottom: 1px;
  background-color: transparent;
}
#google_translate_element .goog-te-gadget-simple, #google_translate_element .goog-te-gadget-simple * {
  font-size: 1rem !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup][href="#"] {
  margin-right: 0;
  display: inline-block;
  text-decoration: none !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup][href="#"] span:nth-of-type(1) {
  padding: 0 3px;
  display: none;
  color: #000000;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup][href="#"] span:nth-of-type(2) {
  padding-right: 3px;
  border-left: 0 !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup][href="#"] span:nth-of-type(3) {
  background: none;
  color: #ffffff !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup][href="#"] img {
  display: none;
}

@media screen and (max-width: 709px) {
  #google_translate_element,
  #goog-gt-tt,
  #goog-gt-tt + div > object,
  iframe.goog-te-menu-frame {
    display: none !important;
  }
}
/*************************
* DISKUZE
*************************/
/*************************
* ÚVOD DISKUZE - PŘEHLED
*************************/
.diskuzeTable td,
.diskuzeTable th,
.diskuzeTable {
  border-color: #EDEDEE;
}

.diskuzeTable th {
  background-color: #EDEDEE;
  border: 1px white solid;
  color: #000000;
  padding: 8px 20px;
}
.diskuzeTable th a, .diskuzeTable th a:link, .diskuzeTable th a:visited,
.diskuzeTable th a:active,
.diskuzeTable th a:focus {
  color: #25426f;
}
.diskuzeTable th a:hover {
  color: #000000;
}
.diskuzeTable div {
  background-color: #f7f6f6;
}
.diskuzeTable th.typfora div {
  background-color: #25426F;
  color: #ffffff;
}

/*************************
* VLASTNÍ DISKUZE
*************************/
.ZOtec,
#legenda .otec {
  background-color: #25426F;
  color: #ffffff;
}

.podzahlavim,
.reakce {
  background-color: #EDEDEE;
}

.ZReakce {
  background-color: #4C6997;
  color: #ffffff;
}

.reakce .podzahlavim {
  background-color: #F3F3F3;
}

.dnesni {
  background-color: #FF0000;
  color: #000000;
}

.vcerejsi {
  background-color: #FECB00;
  color: #000000;
}

.ZReakce a,
.ZReakce a, .ZReakce a:link, .ZReakce a:visited, .ZReakce a:hover, selector:focus, selector:active,
.reakce .ikony a,
#legenda dt.reakce a {
  color: #ffffff;
}

.ZOtec a, .ZOtec a:link, .ZOtec a:visited, .ZOtec a:hover, selector:focus, selector:active,
.otec .ikony a,
#legenda dt.otec a {
  color: #ffffff;
}

.podzahlavim a,
.podzahlavim a:link,
.podzahlavim a:visited,
.podzahlavim a:hover,
.podzahlavim a:focus,
.podzahlavim a:active {
  color: #25426f;
}

#legenda dd {
  margin-left: 3em;
}

.podzahlavim strong {
  color: #000000;
}

#celek .feditace form div {
  border-color: white;
}

.diskuze .ikony,
.diskuze .ikony a {
  width: auto;
}
.diskuze .ikony a {
  padding: 0 5px;
  display: inline-block;
  float: none;
}

/***************************
* PŘEPISY TINYMCE
***************************/
#diskuze .mce-btn button {
  width: auto;
}

#celek .feditace form .mce-container,
#celek .feditace form .mce-container div {
  padding: 0;
}
#celek .feditace form .mce-container .mce-statusbar {
  padding: 3px 5px;
}