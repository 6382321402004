
/******************
* MODULY
******************/

.svatekPocasiPozor{
	width: 15em;
	font-size: rem(16px);
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;

	h2{
		color: $barD;
	}

	.box{
		background-color: rgba($barD, .8);
		height: 158px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		width: 7.25em;
		padding: 10px 0;
		margin: 10px 0;
		box-sizing: border-box;

		*{
			color: white;
			box-sizing: border-box;
		}
	}

	#upozorneni{
		width: 100%;
		margin: 0;
	}

	@include bp("> 450px"){
		width: 100%;
		margin: 0;
		justify-content: space-evenly;

		// #upozorneni{
		// 	width: 7.25em;
		// 	margin: 10px 0;
		// }
	}

	@include bp("> 600px"){
		justify-content: space-between;
		position: absolute;
		top: 10px;
		bottom: 10px;
		left: 0;
		width: 15.5em;
	}

	@include bp("> 850px"){
		max-height: 356px;
	}
	@include bp("> 1100px"){
		width: 7.25em;
		align-items: center;
		max-height: 100%;
		top: 50%;
		bottom: auto;
		transform: translateY(-50%);
		
		.box{
			margin: 5px 0;
			height: 156px;

			&:hover{
				width: 21.75em!important;
	
				&#pocasi{
					.owm2, .owm3{
						display: flex;
					}
				}

				.jmZitra, .jmPozitri{
					display: block;
				}	
				
				// &#upozorneni{
				// 	display: block;
				// 	padding: 10px 10px 30px 106px;
				// 	position: relative;

				// 	h2{
				// 		display: block;
				// 		padding: 8px 0 4px;
				// 		font-size: rem(20px);
				// 	}	

				// 	.vicedok{
				// 		display: block;
				// 		position: absolute;
				// 		bottom: 10px;
				// 		right: 16px;
				// 		text-decoration: underline;
						
				// 		&:hover{
				// 			text-decoration: none;
				// 		}
				// 	}
				// }
			}
		}
	}

	@include bp("> 1400px"){
		.box{
			margin: 10px 0;
			height: 158px;
		}
	}
}

#obalJmeniny {
	.box{
		display: flex;
		flex-wrap: nowrap;
	}
}

.jmeniny {
	text-align: center;
	padding: 0 10px;
	width: 7.25em;

	.datum{
		font-size: rem(30px);
		line-height: 30px;
		font-weight: bold;
		padding-bottom: 7px;
	}

	.svatek{
		font-size: rem(15px);
	}

	.jmeno{
		word-wrap: break-word;
		width: 6em;
	}
}

.jmZitra, .jmPozitri{
	display: none;

	.datum{
		font-size: rem(24px);
	}
}

// .volAdmin + .obalBox{
// 	#upozorneni{
// 		width: 600px !important;
// 		padding: 10px 10px 10px 106px;
// 		display: block;

// 		h2, .vicedok{
// 			display: block;
// 		}
// 	}	
// }

#uvodtitul {
	overflow: hidden;
	margin: 0 0 30px 0;
}

#pocasi{
	font-size: rem(16px);
	padding: 10px 0;
	
	ul{
		display: flex;
		flex-wrap: nowrap;
	}

	li{
		flex-wrap: wrap;
		margin: 0;
		padding: 0 10px;
		width: 7.25em;
		
		img{
			width: 64px;
		}

		span{
			display: block;
			width: 100%;
		}
	}

	.owm1{
		display: flex;
	}

	.owm2, .owm3{
		display: none;
	}
	
	.owmIcon{
		order: 1;
		padding: 0;
	}

	.owmDateDay{
		order: 2;
	}

	.owmTempMax{
		order: 3;
	}	

	.zdroj{
		display: block;
		text-align: center;
		width: 100%;

		@include bp("> 600px"){
			text-align: left;
			padding-left: 38px;
		}
	}

	.owmDateNumber, .owmDateDayName, .owmTempMin{
		display: none!important;
	}
}

#upozorneni {
	font-size: rem(16px);
	color: white;
	background: $barL;
	padding: 16px 3%;
	box-sizing: border-box;
	max-width: 1920px;
	margin: 0 auto;

	a{
		color: white;
		text-decoration: none;

		&:hover{
			text-decoration: underline;
		}
	}

	a, span{
		position: relative;
		line-height: 1.5;
	}

	a > span, .vicedok {
		top: 0;
	}

	.pozor{
		font-weight: 700;

		&::before{
			content: normal;
			display: inline-block;
			width: 41px;
			height: 36px;
			margin: 0 20px 0 0; 
			background: url(images/pozor.png) no-repeat 0 0;
			position: relative;
			top: 10px;
		}
	}

	@include bp("> 600px"){
		padding: 5px 3% 2px;

		.pozor{
			&::before{
				content: "";
			}	
		}

		a, span{
			top: -10px;
		}
	}

	.vicedok{
		text-decoration: underline;

		&:hover{
			text-decoration: none;
		}
	}
}

#temata{
	padding: 20px 0 38px;

	@include bp("> 600px"){
		padding: 30px 0 48px;
	}

	*{
		box-sizing: border-box;
	}

	ul,li{
		@extend %reset-list;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
	}

	.ui li{
		@include grid(500px 2 1.7%, 800px 3, 1100px 4);
		margin-top: 10px;
		margin-bottom: 10px;
		background-color: #f7f7f7;
		position: relative;

		@include bp(">= 500px"){
			min-height: 144px;
		}

		@include bp("< 500px"){
			&:nth-child(2n+2){
				background-color: #ededee;
			}
		}

		@include bp(">= 500px", "< 800px"){
			&:nth-child(4n+2), &:nth-child(4n+3){
				background-color: #ededee;
			}
		}

		@include bp(">= 800px", "< 1100px"){
			&:nth-child(6n+2), &:nth-child(6n+4), &:nth-child(6n+6){
				background-color: #ededee;
			}
		}	

		@include bp(">= 1100px"){
			&:nth-child(8n+2), &:nth-child(8n+4), &:nth-child(8n+5), &:nth-child(8n+7){
				background-color: #ededee;
			}
		}

		&:hover{
			background-color: #e8eaee;

			strong a{
				background-color: $barD;
			}

			div{
				color: $barD;
			}

			.sipkaTemata span{
				&::after{
					content: "";
					position: absolute;
					right: 17px;
					bottom: -22px;
					width: 40px;
					height: 23px;
					background: url(images/sipkaTemata.png) no-repeat 0 0;
					display: block;
				}
			}
		}

		> strong {
			a{
				display: block;
				padding: 16px 16px 14px 18px;
				background-color: $barL;
				color: white;
				font-size: rem(18px);
				font-family: $font;
				font-weight: bold;

				@include bp("> 1200px"){
					font-size: rem(22px);
				}
				@include bp("> 1400px"){
					font-size: rem(26px);
				}

				&::before{
					@include fill;
					content: "";
				}
			}

			&.sipkaTemata span{
				display: block;
				position: relative;
			}	
		}

		div{
			font-size: rem(16px);
			font-family: $font2;
			color: $barL;
			font-weight: 300;
			padding: 28px 16px 28px 18px;

			@include bp("> 1200px"){
				font-size: rem(18px);
			}
		}
	}
}

#kalendar{
	background-color: $barL;
	padding: 40px 0;

	.nadpis{
		color: white;
		padding-bottom: 30px;
	}
}

#vypisakci{
	.seznamAkci{
		position: relative;
		list-style-type: none;
		margin: 0;
		padding: 0;
		width: 100%;

		@include bp("> 500px"){
			min-height: 462px;
		}
	}

	li{
		margin: 0;
		padding: 0;
	}

	.nazevAkce{
		font-size: rem(14px);
		font-family: $font2;
		font-weight: 300;
		padding: 6px 12px;
		background-color: #ffffff;
		box-sizing: border-box;
		text-align: left;
		display: flex;
		align-items: center;
		position: relative;

		@include bp("> 500px"){
			padding: 12px 20px;
			font-size: rem(16px);
			min-height: 77px;
		}		

		@include bp("> 1250px"){
			font-size: rem(18px);
		}

		a{
			color: #000000;
			
			&::before{
				content: "";
				@include fill;
			}
		}

		@include bp("> 800px"){
			width: 50%;
			margin-left: 50%;

			&.selectedSection, &.selectedSection a, &.selectedSection .datumAkce{
				color: white;
				background-color: $barD;
			}
		}	
	}

	.datumAkce{
		font-size: rem(16px);
		color: $barD;
		font-family: $font;
		font-weight: bold;
		float: left;
		width: 6.7em;
		padding: 10px 0;

		@include bp("> 500px"){
			font-size: rem(18px);
			padding: 10px 0 10px 3%;
		}

		@include bp("> 1100px"){
			font-size: rem(22px);
		}

		@include bp("> 1400px"){
			font-size: rem(26px);
		}

		.datum-cislo:not(:empty)::after{
			content: ".";
		}
	}

	@include bp("<= 800px"){
		.obsahAkce{
			display: none!important;
		}
	}

	.obsahAkce{
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 50%;
		background-color: $barD;
		box-sizing: border-box;
		padding: 46px 20px 46px 3%;

		&, a{
			color: white;
		}
	}

	.upoutavka{
		float: left;
		width: 47%;
		max-width: 360px;

		img{
			max-width: 100%;
			max-height: 365px;
			height: auto;
			width: auto;
		}
	}

	.podrobnosti{
		float: right;
		width: 43.5%;
		font-size: rem(18px);
		font-family: $font2;
		font-weight: 300;
		line-height: 1.4;
	}

	.misto{
		display: none;		// schovani Kde:
	}

	.popis:not(:empty){
		margin-bottom: 32px;
	}

	.vice{
		padding-right: 12px;
		background: url(images/sipkaAkce.png) no-repeat right center;
		color: #ffffff;
		font-family: $font;
		font-size: rem(20px);
		position: absolute;
		right: 24px;
		bottom: 24px;
	}

	.progressBar{
		display: none;
	}

	@include bp("> 800px"){
		.progressBar{
			position: relative;
			left: 3%;
			width: calc(46% - 80px);
			height: 2px;
			top: -31px;
			background-color: $barL;
			display: block;
	
			span {
				display: block;
				height: 2px;
				background-color: white;
			}
		}
	}	
}

#filtr{
	padding: 26px 0;
	overflow: hidden;

	ul, li {
		margin: 0;
		list-style: none;
		padding: 0;
		float: left;
	}

	li{
		padding: 1em 1em 0 0;
		max-width: 14.7em;
		min-width: 10em;
		font-size: rem(16px);
		width: 33.3%;
		box-sizing: border-box;
	}

	ul{
		width: calc(100% + 1em);
		max-width: 794px;
		font-size: rem(16px);

		@include bp("> 600px"){
			width: calc(100% + 1.4em);
			font-size: rem(18px);

			li{
				font-size: rem(18px);
				padding: 1em 1.4em 0 0;
			}	
		}
		
		@include bp("> 800px"){
			width: calc(100% - 180px);
		}
	}

	a{
		border: 1px solid white;
		color: white;
		font-family: $font2;
		font-weight: 300;
		display: block;
		padding: 1em;
		box-sizing: border-box;
		border-radius: 5px;
		text-align: center;
		text-decoration: none;

		&:hover{
			text-decoration: underline;
		}
	}

	.vsechny{
		border-color: $barD;
		background-color: $barD;
		float: left;
		margin: 16px 0;
		font-size: rem(16px);
		width: 16%;
		max-width: 13.3em;
		min-width: 10em; 
		color: white;
	
		@include bp("> 600px"){
			font-size: rem(18px);
		}
	}		
}

#kalendarAkci {
	.editor {
		margin-top: 40px;
		margin-bottom: 40px;
	}
}

.rezervace{
	background-color: #f6f6f6;
	padding: 40px 0;

	@include bp("> 600px"){
		padding: 0;

		.centrovany{
			display: flex;
			justify-content: space-between;
			flex-wrap: nowrap;
			align-items: center;
			min-height: 248px;
			position: relative;
		}
	}
	
	.textRezervace{
		box-sizing: border-box;

		@include bp("> 600px"){
			padding: 0 6% 0 0;
			width: calc(100% - 140px);
		}

		@include bp("> 800px"){
			padding: 0 6% 0 41.7%;
			width: 87.7%;

			&::before{
				content: "";
				background: url(images/rezervace.jpg) no-repeat left center;
				width: 35.7%;
				height: 152px;
				background-size: cover;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		p{
			color: #000000;
			font-size: rem(18px);
			font-family: $font2;
			font-weight: 300;
			margin: 0;
		}

		.podnadpis{
			font-weight: bold;
			font-size: rem(26px);
			color: $barD;
			margin: 0;
			padding-bottom: 12px;
		}
	}
	
	a{
		font-family: $font2;
		font-weight: 300;
		display: block;
		padding: 1em;
		box-sizing: border-box;
		border-radius: 5px;
		text-align: center;
		background-color: $barD;
		width: 140px;
		color: white;
		font-size: rem(18px);
		margin-top: 12px; 
		text-decoration: none;

		&:hover{
			text-decoration: underline;
		}
		
		@include bp("> 600px"){
			width: 125px;
			margin: 0;
		}

		@include bp("> 800px"){
			width: 17%;
		}
	}
}

.volnycas{
	padding: 40px 0 55px;

	ul, li{
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	li{
		@include grid(601px 2 1.8%, 1001px 3);
		position: relative;
		padding-bottom: 53.5%;
		max-width: 495px;
		margin: 20px auto 0;
		float: none;

		@include bp("> 600px"){
			padding-bottom: 29%;	
			float: left;
			margin-top: 16px;
			margin-bottom: 16px;
		}

		@include bp("> 1000px"){
			padding-bottom: 19%;	
		}

		a{
			@include fill;
			background-position: center center;
			background-size: cover;

			&::before{
				content: "";
				@include fill;
				top: 100%;
				transition: top 0.75s;
				background: rgba($barD, .8);
			}

			&:hover{
				&::before{		
					top: 0;
				}
					
				span{
					background: transparent;
				}
			}

			span{
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				padding: 0.6em;
				line-height: 1em;
				background: rgba($barD, .8);
				color: white;
				font-family: $font;
				font-size: rem(18px);
				font-weight: bold;

				@include bp("> 800px"){
					font-size: rem(22px);
				}

				@include bp("> 1500px"){
					font-size: rem(26px);
				}	
			}
		}
	}
}

.aboMesta{
	max-width: 1920px;
	margin: 0 auto;
	overflow: hidden;

	h2{
		color: $barD;
	}

	.centrovany{
		>div{
			position: relative;
			box-sizing: border-box;

			&::before, &::after{
				content: "";
				position: absolute;
				top: 0; bottom: 0;
				width: 190px;
			}	
		}

		#abo{
			background-color: #f6f6f6;

			&::before{
				right: 100%;
				background-color: #f6f6f6;
			}

			&::after{
				left: 100%;
				background-color: #f6f6f6;
			}
		}

		.mesta{
			background-color: #ededee;
			padding: 40px 0 40px 4%;

			&::before{
				left: 100%;
				background-color: #ededee;
			}

			&::after{
				right: 100%;
				background-color: #ededee;
			}
			
			a{
				color: $barD;
				display: inline-block;
				font-size: rem(18px);
				font-weight: 300;
				font-family: $font2;
				text-decoration: none;

				img{
					display: inline-block;
					margin: 5px 0 25px;
				}

				&:hover{
					text-decoration: underline;
				}
			}
		}
		
		@include bp("> 800px"){
			display: flex;
			flex-wrap: nowrap;

			>div::after{
				content: normal;	
			}

			#abo{
				width: 66.3%;
			}
			
			.mesta{
				width: 33.7%;
			}
		}	
	}
}

#obalmbannery{
	min-width: 320px;
	background: #ffffff;
}

#mbannery {
	padding: 45px 0;
	color: #000000;
	margin: 0;

	.editor {
		text-align: center;

		@include link-all {
			color: #000000 !important;
		}
	}
	
	li { 
		margin-top: 0;
		margin-bottom: 0;
	}
}

#kontakt {
	address {
		display: block;
		font-style: normal;
	}
}

#anketa {
	p {
		padding: 0;
		margin: 0 0 1em 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 8px 0 5px 0;

		li {
			margin-bottom: 8px;
		}
	}

	.hlas {
		margin-top: 3px;
		z-index: 1;
		
		div {
			height: 12px;
			background-color: $color-main;
		}
	}
}

#pocitadlo {
	margin: 15px 0 0 0;
	text-align: center;
	padding-bottom: 15px;

	ul.inline {
		padding-left: 0;
		display: inline;

		li span {
			margin: 0 0 0 2px;
		}
	}

	@include bp("< 600px"){
		li {
			display:block!important;
	
			+ li::before{
				content: "";
				margin:0;
			}	
		}
	}
}

